import {
  createSearchURLParams,
  createSelectOptionForReactSelect,
  get_bulk_settings,
  get_informationFromSessionID,
  get_material_datails_from_FSV,
  get_plain_value_from_inputs,
  get_property_settings,
  get_values_base_on_short_code_from_FSV,
  get_which_master_from_FSV,
} from "../../Component/Helper";
import {
  Pcs_stepper_calc,
  gross_weight_converstion,
  gst_and_all_total_calc,
  karat_amount_calc,
  karat_to_weight_converstion,
  labour_calc,
  material_all_total,
  size_to_weight_converstion,
} from "../../calculation";
import { set_FSV_Handler } from "../../redux/Reducer/reducer";

export const FSV_ACTIONS = {
  SET_PRODUCTS: "SET_PRODUCTS",
  SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT",
  SET_PRODUCT_CALC: "SET_PRODUCT_CALC",
  SET_REMARKS: "SET_REMARKS",
  SET_QUANTITY: "SET_QUANTITY",
  SET_SELECTED_SUBPRODUCTS: "SET_SELECTED_SUBPRODUCTS",
  RESET_ALL: "RESET_ALL",
  SET_SETS_STEPPER_QUANTITY: "SET_SETS_STEPPER_QUANTITY"
};

const { size_diameter_based_wt_change, size_change_wt_on } = get_bulk_settings([
  "size_diameter_based_wt_change",
  "size_change_wt_on",
]);

export function get_short_code_name(short_code) {
  if (short_code === "selection_karat_catalogue") {
    return "karat";
  } else if (short_code === "selection_multiple_grosswt") {
    return "selected_gross_wt";
  } else if (short_code === "selection_tone_catalogue") {
    return "tone";
  } else if (short_code === "selection_piece_catalogue") {
    return "piece";
  } else if (short_code === "selection_size_catalogue") {
    return "size";
  } else {
    return short_code;
  }
}

export function get_default_selected(product) {
  let defaultValues = {};

  if (!product) return defaultValues;
  const selectType = get_property_settings("catalogue_selection_based_on_web");
  // const selectType = "dropdown"

  const selection_position = product?.selection_position;

  if (Array.isArray(selection_position) && selection_position.length > 0) {
    const selection_template = [
      { short_code: "selection_multiple_grosswt", defaults_key: "gross_wt" },
      { short_code: "selection_karat_catalogue", defaults_key: "karat" },
      { short_code: "selection_size_catalogue", defaults_key: "size" },
      { short_code: "selection_tone_catalogue", defaults_key: "tone" },
      {
        short_code: "selection_diamond_catalogue",
        defaults_key: "diamond_group",
      },
      { short_code: "cc_hook", defaults_key: "cc_hook" },
      { short_code: "cc_order_by", defaults_key: "cc_order_by" },
      { short_code: "cc_cc_hook_pref", defaults_key: "cc_cc_hook_pref" },
      { short_code: "length_preference", defaults_key: "length_preference" },
      //isObject: if this selection value is not string it's object (e.g {product_id: "459", variant_name: "75GM - 2.5IN"}) / defaults: "variant": "75GM - 2.5IN"
      {
        short_code: "selection_variant_catalogue",
        defaults_key: "variant",
        isObject: true,
        label_value: "variant_name",
        id_value: "product_id",
      },
    ];

    selection_template.forEach((template) => {
      const selection = selection_position.find(
        (selection) => selection.short_code === template.short_code
      );

      if (selection && product[selection?.short_code]) {
        const key = template.defaults_key;
        let value = "";
        const default_key_value = product?.defaults[key];
        if (template.isObject) {
          //if selection value is object
          // eslint-disable-next-line array-callback-return
          product[template.short_code].find((selection_value_obj) => {
            if (
              selection_value_obj[template.label_value] === default_key_value
            ) {
              value = selection_value_obj[template.id_value];
            }
          });
        } else {
          //if selection value is string
          value = product?.defaults[key];
        }
        const short_code = get_short_code_name(template?.short_code);

        defaultValues = {
          ...defaultValues,
          [short_code]:
            selectType === "dropdown"
              ? createSelectOptionForReactSelect(value)
              : value,
        };

        // defaultValues[short_code] = selectType === "dropdown" ? createSelectOptionForReactSelect(product?.defaults[key]) : product?.defaults[key]
      } else {
        //code for if selection_position.short_code value and selection_template.short_code value not match (null)
      }
    });
  }

  return defaultValues;
}

export function setValues(defaultValue, required_keys_array, setValue) {
  required_keys_array?.forEach((name) => {
    setValue(name, defaultValue[name]);
  });
}

export function update_default_selected_to_all_subproduct(
  allProduct,
  category_name,
  updatedProducts
) {
  let Obj = {};
  const updatedProducts_keys = Object.keys(updatedProducts);
  category_name.forEach((category) => {
    if (!updatedProducts_keys.includes(category)) {
      const getProduct = allProduct.find(
        (prod) => prod.category_name === category
      );
      Obj[category] = {
        ...get_default_selected(getProduct),
        quantity: Number(getProduct?.min_order_quantity) || 1,
      };
    }
  });

  return { ...updatedProducts, ...Obj };
}

export function set_cart_params(
  selectedProduct,
  products_calc,
  typeOfCart,
  quantity,
  remarks,
  master,
  sets_quantity,
  random
) {
  const userId = get_informationFromSessionID("userID");
  const selectType = get_property_settings("catalogue_selection_based_on_web");

  const selected_gross_wt =
    selectType === "dropdown"
      ? products_calc?.selected_gross_wt?.value
      : products_calc?.selected_gross_wt;
  const size =
    selectType === "dropdown"
      ? products_calc?.size?.value
      : products_calc?.size;
  const piece =
    selectType === "dropdown"
      ? products_calc?.piece?.value
      : products_calc?.piece;
  const karat =
    selectType === "dropdown"
      ? products_calc?.karat?.value
      : products_calc?.karat;
  const tone =
    selectType === "dropdown"
      ? products_calc?.tone?.value
      : products_calc?.tone;
  const diamond_catalogue =
    selectType === "dropdown"
      ? products_calc?.selection_diamond_catalogue?.value
      : products_calc?.selection_diamond_catalogue;

  const { which_master, master_id } =
    get_which_master_from_FSV(selectedProduct);

  const short_codes = get_values_base_on_short_code_from_FSV(
    selectedProduct?.short_code,
    selectedProduct?.values,
    ["gross_wt", "net_wt", "total_cost", "default_karat_id"]
  );

  const material_details = get_material_datails_from_FSV(
    selectedProduct?.material,
    [
      "pieces",
      "weight",
      "material_id",
      "cut_id",
      "sieve_size_id",
      "material_table_id",
      "cts",
      "shape_id",
      "other_charges",
      "clarity_id",
      "color_id",
    ]
  );

  //Check QTY if sets Quantity is enabled
  let updated_quantity = quantity
  let updated_pcs = piece
  let change_wt_based_on_qty = get_property_settings("change_wt_based_on_qty")
  let allow_lessthan_moq_type = get_property_settings("allow_lessthan_moq_type")
  change_wt_based_on_qty = change_wt_based_on_qty === "Yes"
  let show_qty_stepper_in_inventory = get_property_settings("show_qty_stepper_in_inventory")
  show_qty_stepper_in_inventory = show_qty_stepper_in_inventory === "Yes"
  if(change_wt_based_on_qty){
    let checkQtyFlag  = false;
    if(master === "inventory_master" && show_qty_stepper_in_inventory){
      checkQtyFlag = true
    }else if(master === "design_master"){
      checkQtyFlag = true
    }

    if(checkQtyFlag){
      let enable_set_and_pcs_logic = selectedProduct?.enable_set_and_pcs_logic;
      enable_set_and_pcs_logic = enable_set_and_pcs_logic === "1"
      if(allow_lessthan_moq_type === "category"){
        if(!enable_set_and_pcs_logic){
          updated_pcs = selectedProduct?.no_pcs;
        }else{
          updated_pcs = quantity
          updated_quantity = sets_quantity
        }
      }
      else{
        updated_pcs = quantity
      }
    }else{
      updated_quantity = piece
    }
  }
  // console.log(selectedProduct, "selectedProduct")
  // console.log(short_codes, "short_codes")
  // console.log(products_calc, "products_calc")
  const params = {
    user_id: userId ? userId : "",
    type_of_user: "normal_user",
    session_id: "",
    quantity: updated_quantity,
    table: typeOfCart === "wishlist" ? "temp_wishlist" : "temp_cart",
    product_id:
      products_calc?.selection_variant_catalogue ||
      selectedProduct[master_id] ||
      "",
    parent_id: selectedProduct[master_id] || "",
    design_inventory: which_master,
    gross_wt: products_calc?.calc_gross_weight || short_codes?.gross_wt || "",
    net_wt: products_calc?.calc_net_weight || short_codes?.net_wt || "",
    selected_gross_wt: selected_gross_wt || short_codes?.gross_wt || "",
    size: size || "",
    piece: updated_pcs || selectedProduct?.no_pcs || "",
    karat: karat || short_codes?.default_karat_id || "",
    tone: tone || "",
    certification: "",
    hallmarking: "",
    diamond_catalogue_id: diamond_catalogue || "",
    diamond_clarity_id: "",
    diamond_color_id: "",
    price:
      products_calc?.calc_total_after_tax_amt || short_codes?.total_cost || "",
    remarks,
    random: random || new Date().toLocaleString(),
    device: "web",
    materials: material_details,
  };
  
  return params;
}

export function calculate_prod(
  selectedProduct,
  products_calc,
  short_code,
  value,
  fsv_count,
  dispatch,
  fsv_sets_count,
  cartData = {}
) {
  const category_name = selectedProduct?.category_name;
  
 
  // const selectType = "dropdown"
  const selectedValue = products_calc[category_name];
  const current_prod_calc = {};
  
  let selected_gross_wt =
    short_code === "selected_gross_wt"
      ? get_plain_value_from_inputs(value)
      : get_plain_value_from_inputs(selectedValue?.selected_gross_wt) || cartData?.selected_gross_wt;
  let karat =
    short_code === "karat"
      ? get_plain_value_from_inputs(value)
      : get_plain_value_from_inputs(selectedValue?.karat);
  let size =
    short_code === "size"
      ? get_plain_value_from_inputs(value)
      : get_plain_value_from_inputs(selectedValue?.size);
 
 
  if (selectedProduct && selectedProduct?.karat_rates) {
    const metal_rate =
      selectedProduct?.karat_rates?.find((karat_rate) => {
        return karat_rate.karat_value?.toString() === karat?.toString();
      })?.rate || "0";

    current_prod_calc["metal_rate"] = metal_rate;
    
    const { grossWeight: grossWeight1, netWeight: netWeight1 } =
      gross_weight_converstion(selected_gross_wt, selectedProduct);

    const { grossWeight, netWeight } = karat_to_weight_converstion(
      karat,
      grossWeight1,
      netWeight1,
      selectedProduct
    );
    
    const last_values = size_to_weight_converstion(
      size,
      grossWeight,
      netWeight,
      size_change_wt_on,
      size_diameter_based_wt_change,
      selectedProduct
    );
    // console.log(last_values?.grossWeight, last_values?.netWeight, "calc")
    //calc karat Rate
    const { karat_amount } = karat_amount_calc(
      karat,
      last_values?.grossWeight,
      last_values?.netWeight,
      selectedProduct
    );

    current_prod_calc["calc_karat_rate"] = karat_amount;

    // Calc labour
    const { labourAmount, thisLabourVal, wasteage } = labour_calc(
      size,
      karat,
      last_values?.grossWeight,
      last_values?.netWeight,
      selectedProduct
    );
    current_prod_calc["calc_making_charge"] = thisLabourVal;
    current_prod_calc["calc_wasteage"] = wasteage;

    //Calc Total Material
    const Total_Mat_Amt = material_all_total(
      selectedProduct.material,
      "total",
      false
    );
    current_prod_calc["calc_total_material_amt"] = Total_Mat_Amt;

    //Calc wt based on Stepper PCs
    
    const { newGW, newNW } = Pcs_stepper_calc(
      last_values?.grossWeight,
      last_values?.netWeight,
      fsv_count,
      selectedProduct,
      fsv_sets_count
    );
    // console.log(grossWeight1, netWeight1, "1")
    // console.log(grossWeight, netWeight, "2")
    // console.log(last_values?.grossWeight, last_values?.netWeight, "3")
    // console.log(newGW, newNW, "4")
    current_prod_calc["calc_gross_weight"] = newGW;
    current_prod_calc["calc_net_weight"] = newNW;
    
    //GST and Total Calc
    const { GST, total_after_tax } = gst_and_all_total_calc(
      karat_amount,
      Total_Mat_Amt,
      labourAmount,
      0,
      selectedProduct
    );

    current_prod_calc["calc_GST_amt"] = GST;
    current_prod_calc["calc_total_after_tax_amt"] = total_after_tax;

    if (short_code && value) {
      dispatch(
        set_FSV_Handler({
          type: FSV_ACTIONS.SET_PRODUCT_CALC,
          payload: {
            product_name: category_name,
            data: { [short_code]: value, ...current_prod_calc },
          },
        })
      );
    } else {
      dispatch(
        set_FSV_Handler({
          type: FSV_ACTIONS.SET_PRODUCT_CALC,
          payload: {
            product_name: category_name,
            data: current_prod_calc,
          },
        })
      );
    }
  }
}

export function merge_product_and_subproduct(FSV_Data) {
  if (FSV_Data.subproducts.length > 0) {
    return [FSV_Data, ...FSV_Data?.subproducts];
  } else {
    return [FSV_Data];
  }
}

export function get_fsv_prod_details(property, selectedProd) {
  let details = {};
  if (Array.isArray(property)) {
    property.forEach((prop) => {
      details[prop] = selectedProd[prop];
    });
  } else {
    details[property] = selectedProd[property];
  }

  return details;
}

export function handle_fsv_button_value(short_code, value) {
  if (short_code === "selection_variant_catalogue") {
    return { value: value?.product_id, label: value?.variant_name };
  } else {
    return value;
  }
}

export function FSV_data_url(params) {
  let { pathname, search } = window.location;
  pathname = pathname.replace("/", "");
  const searchParams = Object.fromEntries(new URLSearchParams(search));
  return createSearchURLParams(pathname, { ...searchParams, ...params }); //"my_catalogue"
}

export function params_short_code(short_code) {
  return short_code === "selected_gross_wt" || short_code === "gross_wt"
    ? "selection_multiple_grosswt"
    : short_code === "selected_karat" || short_code === "karat"
    ? "selection_karat_catalogue"
    : short_code === "selected_size" || short_code === "size"
    ? "selection_size_catalogue"
    : short_code === "selected_tone" || short_code === "tone"
    ? "selection_tone_catalogue"
    : short_code;
}

export const FSV_handle_recall_api_on_change = (
  formData,
  selected_value,
  short_code
) => {
  const last_selected_value = {
    lv_gross_wt: formData["selected_gross_wt"],
  };
  const lv_net_wt = last_selected_value?.lv_gross_wt || "";
  const Other_params = {
    recall_api_on_change: "1",
    recall_api_on_change_short_code: params_short_code(short_code),
  };
  const form_data = {
    ...formData,
    [short_code]: selected_value,
    ...last_selected_value,
    lv_net_wt,
    ...Other_params,
  };

  return FSV_recall_api_params_url(form_data);
};

export const FSV_recall_api_params_url = (form_data) => {
  const keys = Object.keys(form_data);
  const values = Object.values(form_data);

  const form_data_params = keys.reduce((obj, params, index) => {
    const short_code = params_short_code(params);
    if (!short_code.includes("custom")) {
      obj[short_code] = form_data[`custom_${params}`] || values[index];
    }
    return obj;
  }, {});

  return form_data_params;
};

export function recall_api_on_change(
  selectedProduct,
  short_code,
  master,
  searchParams
) {
  let refresh_fsv_on_catalogue_pressed = get_property_settings(
    "refresh_fsv_on_catalogue_pressed"
  );
  refresh_fsv_on_catalogue_pressed = refresh_fsv_on_catalogue_pressed === "Yes";

  const edit_short_code = params_short_code(short_code);

  const recall_api_on_change =
    selectedProduct["selection_position"].find(
      (select) => select.short_code === edit_short_code
    )?.recall_api_on_change === "1";

  master = searchParams.table || master;
  //Here Check if recall API on change
  const recall_api =
    refresh_fsv_on_catalogue_pressed &&
    recall_api_on_change &&
    master === "design_master";

  return recall_api;
}


export function update_FSV_defaults_with_cart_data(default_section, cartData, selectedProduct){
    const gross_wt_short_code = selectedProduct?.selection_position.find(selection => selection.short_code.includes("grosswt"))?.short_code
    const selection_multiple_grosswt = gross_wt_short_code ? selectedProduct[gross_wt_short_code] : []
    
  for(let key in cartData){
      if(key === "gross_wt" && selection_multiple_grosswt.includes(cartData[key])){
        for(let fsv_key in default_section){
          if(fsv_key.includes("gross_wt")){
            default_section[fsv_key] = cartData[key]
          }
        }
      }else if(key === "diamond_catalogue_id" && !!cartData["diamond_catalogue_id"] && default_section["selection_diamond_catalogue"]){
        default_section["selection_diamond_catalogue"] = cartData["diamond_catalogue_id"]
      }
      else if(default_section[key]){
        default_section[key] = cartData[key]
      }
    }
}