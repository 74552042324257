import scrollBtnImg from "../../assets/images/favicon/up-arrow.png"
import { FaArrowCircleUp } from "react-icons/fa";

function ScrollBtn() {
  return (
    <div
      className="top-Btn d-flex justify-content-center"
      id="scroll-to-top-btn"
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >

      <FaArrowCircleUp style={{fontSize: "1.75rem"}}/>
      {/* <img
        src={scrollBtnImg}
        height="20"
        width="20"
        alt="scroll-pic"
      /> */}
      <button>Scroll To Top</button>
    </div>
  );
}

export default ScrollBtn;
