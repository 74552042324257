import React, { useEffect } from "react";
import { NumericFormat } from "react-number-format";
import {
  ToastError,
  get_property_settings,
} from "../../../../../../../Component/Helper";
import { useSelector, useDispatch } from "react-redux";
import {
  get_fsv_products_calc,
  get_fsv_quantity,
  get_fsv_sets_quantity,
  getCatMaster,
  set_FSV_Handler,
} from "../../../../../../../redux/Reducer/reducer";
import { calculate_prod, FSV_ACTIONS } from "../../../../../Helper";

function Stepper({selectedProduct, cartCount}) {
  const allow_lessthan_moq =
    get_property_settings("allow_lessthan_moq") === "Yes";
  const allow_lessthan_moq_type = get_property_settings("allow_lessthan_moq_type")
 
  
  const products_calc = useSelector(get_fsv_products_calc);
  const dispatch = useDispatch();
  

  const maxQty = parseInt(selectedProduct?.max_order_quantity);
  const minQty = parseInt(selectedProduct?.min_order_quantity);
  const count = useSelector(get_fsv_quantity);
  const sets_counts = useSelector(get_fsv_sets_quantity);

  //Show Availabel Qty Label
  let show_unique_inventory_designs = get_property_settings("show_unique_inventory_designs");
  show_unique_inventory_designs = show_unique_inventory_designs === "Yes"

  let selected_master = useSelector(getCatMaster)
  selected_master = selected_master === "inventory_master"

  let selection_multiple_inventory = selectedProduct?.selection_multiple_inventory
  selection_multiple_inventory = selection_multiple_inventory ? Object.keys(selection_multiple_inventory).length > 0 : false
  
  const show_available_qty = show_unique_inventory_designs && selected_master && selection_multiple_inventory
  const disabled_btn = show_available_qty && parseInt(count) === parseInt(maxQty)? "disabled" : ""
  
  
  

  const Increment = () => {
    const add = parseInt(count) + 1;

    if (parseInt(maxQty) === 0) {
      dispatch(
        set_FSV_Handler({ type: FSV_ACTIONS.SET_QUANTITY, payload: add })
      );
      calculate_prod(selectedProduct, products_calc, null, null, add, dispatch, sets_counts)
    } else {
      if (add > parseInt(maxQty)) {
        if(!show_available_qty){

          return ToastError(`Max ${maxQty} Qty Reached.`);
        }
      } else {
        dispatch(
          set_FSV_Handler({ type: FSV_ACTIONS.SET_QUANTITY, payload: add })
        );
        calculate_prod(selectedProduct, products_calc, null, null, add, dispatch, sets_counts)
      }
    }
  };

  const Decrement = () => {
    const minus = parseInt(count) - 1;

    if (minus > 1 && allow_lessthan_moq) {
      dispatch(
        set_FSV_Handler({ type: FSV_ACTIONS.SET_QUANTITY, payload: minus })
      );
      calculate_prod(selectedProduct, products_calc, null, null, minus, dispatch, sets_counts)
    } else if (parseInt(minus) >= Number(minQty)) {
      dispatch(
        set_FSV_Handler({ type: FSV_ACTIONS.SET_QUANTITY, payload: minus })
      );
      calculate_prod(selectedProduct, products_calc, null, null, minus, dispatch, sets_counts)
    }
  };

  useEffect(() => {
    if(allow_lessthan_moq_type === "category"){
      if(selectedProduct.enable_qty_equal_pcs === "1"){
        
        dispatch(
          set_FSV_Handler({ type: FSV_ACTIONS.SET_QUANTITY, payload: cartCount || selectedProduct?.no_pcs })

        );
      }
    }else{
      if(allow_lessthan_moq){
        dispatch(
          set_FSV_Handler({ type: FSV_ACTIONS.SET_QUANTITY, payload: cartCount || selectedProduct?.no_pcs })
        );
      }else{

        dispatch(
          set_FSV_Handler({ type: FSV_ACTIONS.SET_QUANTITY, payload: cartCount || minQty })
        );
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container fsv-qty-stepper">
      <div className="row product-details-inner-group-sm">
        <div className="col-sm-5 align-self-center label d-flex product-details-label">
          Pcs:
        </div>
        <div className=" col-sm-7 fsv-stepper-container">
          <div className="qty-plus-minus">
            <NumericFormat
              className="qty-input"
              placeholder="qty"
              value={count}
              isAllowed={(values) => {
                
              }}
            />

            <div className="dec ec_qtybtn" onClick={Decrement}>
              -
            </div>
            <div className={`inc ec_qtybtn ${disabled_btn}`} onClick={Increment}>
              +
            </div>
          </div>

          {show_available_qty && <div className="fsv-available-qty">
        <span>Availabel Qty: </span>
        <span>{selectedProduct?.max_order_quantity}</span> 
      </div>}
        </div>
      </div>
      
    </div>
  );
}

export default Stepper;
