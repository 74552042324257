import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ChangePasswordModal.css";
import { useEffect, useState } from "react";
import { useForm, FormProvider, useFormContext, useWatch } from "react-hook-form"
import { fetch_data_from_api } from "../../../../../../../Api/APICalls";
import { ApiList } from "../../../../../../../Api/ApiList";
import { set_activeUser, ToastError, ToastSuccess } from "../../../../../../Helper";
import { color } from "framer-motion";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "min(400px, 90%)",
  // bgcolor: "background.paper",
  bgcolor: "var(--login_register_bg_color)",
  //   border: '2px solid #000',
  color: "var(--login_register_fg_color)",
  boxShadow: 24,
  p: 4,
};



function ChangePasswordModal({
  openChangePasswordModal,
  handleCloseChangePasswordModal,
  handleUserProfileMenuClose = () => {}
}) {

  const methods = useForm({
    mode: "onSubmit"
  })
  const onSubmit = async (data) => {
    const news_letter_subscribe_response = await fetch_data_from_api(`${ApiList.UserRegistration}/${ApiList.change_password}`, ApiList.change_password, data)
    
    if(news_letter_subscribe_response?.ack === "1"){
      ToastSuccess(news_letter_subscribe_response?.msg)
      //udapted password with new password in local
      set_activeUser({password: btoa(data?.new_password || "")})
      handleCloseChangePasswordModal()
      handleUserProfileMenuClose()
    }else if(news_letter_subscribe_response?.error){
      ToastError(news_letter_subscribe_response?.error)
    }
  }

  useEffect(() => {
    document.body.classList.add("hide-user-profile-dropdown")
  }, [])
  

  return (
    <Modal
      open={openChangePasswordModal}
      onClose={() => {
        
        handleCloseChangePasswordModal()
        handleUserProfileMenuClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="change-password--modal"
    >
      <Box sx={style}>
      <FormProvider {...methods}>

        <form className="change-password-container" onSubmit={methods.handleSubmit(onSubmit)}>
          <header>
            <h3>Change Password</h3>
          </header>

          <PasswordGroup placeholder={"Old Password"} short_code="old_password"/>
          <PasswordGroup placeholder={"New Password"} short_code="new_password"/>
          <button type="submit">Change Password</button>
        </form>
      </FormProvider>
      </Box>
    </Modal>
  );
}

function PasswordGroup({ placeholder, short_code }) {
  const [showPassword, setShowPasswod] = useState(false);
  const { register, formState: {errors}, control } = useFormContext()
  const old_password_watch = useWatch({
    control,
    name: "old_password",
  })


  
  return (
    <div className="password-group">
      <section>
        <input
          type={showPassword ? "text" : "password"}
          placeholder={placeholder}
          {...register(short_code, {
            required: {
              value: short_code === "old_password" ? true : old_password_watch ? true : false,
              message: `Enter Password`
            },

            minLength: {
              value: 6,
              message: "Please enter vaild password"
            }
          })}
        />
        {showPassword ? (
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => setShowPasswod((pre) => !pre)}
            
          />
        ) : (
          <FontAwesomeIcon
            icon={faEyeSlash}
            onClick={() => setShowPasswod((pre) => !pre)}
          />
        )}
      </section>
      {errors[short_code] ? <p className="error">{errors[short_code]?.message}</p> : null}
    </div>
  );
}

export default ChangePasswordModal;
