import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./style/matrix.css";
import { useDispatch, useSelector } from "react-redux";
import { ApiList } from "../../Api/ApiList";
import { useNavigate } from "react-router-dom";
import Loader from "../../Component/Loader";
import "../../CSS/Animation.css";
import {
  get_informationFromSessionID,
  set_informationToSessionID,
  get_designStatus,
  get_settings,
  createSearchURLParams,
  get_property_settings,
  delete_informationFromSessionID,
} from "../../Component/Helper";
import { useLocation } from "react-router-dom";
import NoProduct from "../../Component/NoProduct/NoProduct";
import Pagination from "../../Component/Pagination/Pagination";
import FilterSortingSection from "./FilterSortingSection";
//import ScrollToAnchor from "../../Component/ScrollToAnchor";
import FilterSideBarOptions from "./FilterSideBarOptions/FilterSideBarOptions";
import { fetch_data_from_api_from_query } from "../../Api/APICalls";
import ProductNew from "../../Component/Product/ProductNew";
import {
  getCatMaster,
  get_is_myc_web,
  get_martix_view_layout,
  get_myc_allow_access_status,
  get_myc_catalogue_title,
  get_myc_is_secured,
  setWhatsAppMessage,
  set_is_allow_access_myc_to_user,
} from "../../redux/Reducer/reducer";
import ArrowKeysReact from "arrow-keys-react";
import LoginPopUp_for_secure_user_modal from "./LoginPopUp_for_secure_user_modal";

import RequestAccessModal from "../../Component/RequestAccessModal/RequestAccessModal";
import { useQuery } from "@tanstack/react-query";
import PageNotFound from "../PageNotFound/PageNotFound";
import ScrollBtn from "./ScrollBtn";
import { displayed_product_in_matrix_myc_for_secure, ScrollToTopCustom } from "../../utils";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../Custom/CompanyCustomization";
import { getCustomValue } from "../../Custom/CompanyList";
import MatrixBranding from "./Components/MatrixBranding/MatrixBranding";
import ProductNameHeader from "./Components/ProductNameHeader/ProductNameHeader";
import useDelayLoader from "../../Hooks/useDelayLoader";
import useLoginStatus from "../../Hooks/useLoginStatus";



const MatrixViewNew = () => {
  const location = useLocation();
  const [showSideBar, setShowSideBar] = useState(false);
  const [showScrollBtn, setShowScrollBtn] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const MatrixPageRef = useRef(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const show_matrix_pagination_at_both = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_matrix_pagination_at_both)
  let show_web_inline_matrix_filter_option = get_property_settings("show_web_inline_matrix_filter_option")
  show_web_inline_matrix_filter_option = show_web_inline_matrix_filter_option === "Yes";
  
  const is_pagination_active = true;
  const dispatch = useDispatch();
  const userID = get_informationFromSessionID("userID");
  const [hasFilterParams, setHasFilterParams] = useState(true)
  let searchParams = Object.fromEntries(new URLSearchParams(location.search));
  
  let settings = useSelector((state) => state.reducer.settings);
  settings =
    Array.isArray(settings) && settings.length === 0
      ? get_settings()
      : settings;
  
  const userId = get_informationFromSessionID("userID");
  const defaultData = settings?.default_matrix_page_load;
  

  //LayoutView
  const layoutView = useSelector(get_martix_view_layout)
  const layoutViewClass = window.innerWidth <= 575 ? layoutView : ''
  

  //MYC Veriables
  const is_myc_web = useSelector(get_is_myc_web);
  const catelogue_title = useSelector(get_myc_catalogue_title);
  const is_myc_secure = useSelector(get_myc_is_secured);
  const is_myc_allow_to_user = useSelector(get_myc_allow_access_status);

  const get_secure_product_count = useMemo(() => {
    if (!is_myc_web || userID) return null;
    const product_count = displayed_product_in_matrix_myc_for_secure(layoutView)
    return product_count
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [is_myc_secure, searchParams?.page, layoutView]) 
  
  const [request_access, set_request_access] = useState(false);
  
  
  const master = useSelector(getCatMaster);
 
  const design_status = get_designStatus(master);

  const navigate = useNavigate();
  //const currProd = get_informationFromSessionID("currProd");
  
  const matrixProductsSectionRef = useRef(null);


  const img_type_six = searchParams?.img_type === "6" && window.innerWidth > 576
  const prevPageRef = useRef(searchParams?.page)
  
  const urlpage = Number(searchParams?.page) || 0;

  //bottom pagination
  const show_matrix_pagination_at_bottom = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_matrix_pagination_at_bottom)
  const show_matrix_branding_banner = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_matrix_branding_banner)
  

  // useDisablePinchZoomEffect()

  const default_matrix_page_load = get_property_settings(
    "default_matrix_page_load"
  );

  let default_sort_pin_products = get_property_settings(
    "default_sort_pin_products"
  );
 
  default_sort_pin_products = default_sort_pin_products === "Yes";
  

  let filterParams = get_informationFromSessionID("filterParams") || {};

  useLoginStatus()

  const saveStateToLocalStorage = useCallback((index) => {
    set_informationToSessionID({currProd: Number(index)})

    set_informationToSessionID({
      filter: {
        sort: searchParams?.sort || 0,
        page: searchParams?.page || 0,
        ...searchParams,
      },
    });
  }, [searchParams]);

  function closeSideFilterSection(e) {
    if (e.key === "Escape") {
      setShowSideBar(false);
    }
  }

  useEffect(() => {
    if (showSideBar) {
      document.body.style.overflow = "hidden";
      window.addEventListener("keydown", closeSideFilterSection);
    } else {
      document.body.style.overflowY = "scroll";
    }

    return () => window.removeEventListener("keydown", closeSideFilterSection);
  }, [showSideBar]);

  //Rest Filter Params
  if(Object.keys(filterParams).length && filterParams?.cat_id !== searchParams?.cat_id){
    delete_informationFromSessionID("filterParams")
    filterParams = {}
  }
 if(filterParams?.mode === "all" && searchParams?.mode === "all"){
  set_informationToSessionID({filterParams: {...filterParams, mode: "filter"}})
 }

  
  
  const matrix_data_count_query = useQuery({
    queryKey: [{ searchParams }, userID, "matrix-count"],
    queryFn: () =>
      fetch_data_from_api_from_query(
        ApiList.productMatrixCount,
        "matrix-count",
        {
         
          cat_id: 0,
          table: master || "",
          design_status: design_status,
          ...searchParams,
          ...filterParams,
          mode: searchParams?.mode === "all" ? "all_filter" : filterParams?.mode || searchParams.mode || "catalogue_filter"
        }
      ),

    enabled: searchParams.mode !== "featuredBased",
  });

  

  const matrix_data_query = useQuery({
    queryKey: [{ searchParams }, "matrix-data", userID],
    queryFn: () =>
      fetch_data_from_api_from_query(ApiList.matrix, "matrix-data", {
        data: defaultData || "30",
        mode: "catalogue_filter",
        cat_id: 0,
        table: master,
        design_status: design_status,
        page: searchParams?.page || "0",
        ...searchParams,
        ...filterParams,
        // design_status: searchParams?.design_status || filterParams?.design_status || "",
        sort: searchParams?.sort || (default_sort_pin_products ? "is_pinned" : "0"),
      }),
    initialData: [],
    // placeholderData: (previousData, previousQuery) => previousData,
    enabled:
      (matrix_data_count_query?.status === "success" &&
        matrix_data_count_query?.data?.count > 0) ||
      searchParams.mode === "featuredBased",
  });

  function handlePage(page) {
    const url = createSearchURLParams("matrix-page", {
      ...searchParams,
      page: page,
    });
    navigate(url);
    // MatrixPageRef.current?.focus();
  }

  let totalProductCount = useMemo(
    () => matrix_data_count_query?.data?.count,
    [matrix_data_count_query?.data?.count]
  );

  useLayoutEffect(() => {
    
    if (searchParams.mode === "featuredBased") return;
    set_informationToSessionID({ totalProductCount });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalProductCount]);

  
  const total_pages = Math.ceil(
    Number(matrix_data_count_query?.data?.count || totalProductCount) /
      Number(default_matrix_page_load)
  );

  ArrowKeysReact.config({
    left: () => {
      if (is_myc_secure && !userId) return navigate("/login");
      if (urlpage === 0 || showSideBar) return;
      setLoading(true);
      handlePage(urlpage - 1);
    },
    right: () => {
      if (is_myc_secure && !userId) return navigate("/login");
      if (urlpage === total_pages - 1 || showSideBar) return;
      setLoading(true);
      handlePage(urlpage + 1);
    },
  });

  function handle_myc_login_popup() {
    if(showLoginPopup) return
    let first_secure_product_card = document.getElementById(get_secure_product_count.toString())
    
    const first_product_of_each_page = Number(defaultData) * Number(searchParams.page)
    if(!first_secure_product_card && get_secure_product_count <= first_product_of_each_page  && searchParams?.page){
      
      first_secure_product_card = document.getElementById(first_product_of_each_page.toString())
    }
    if(get_secure_product_count && first_secure_product_card){
      const {top, height} = first_secure_product_card.getBoundingClientRect()
      const offsetCardPosition = top + (height / 2)
      
      //console.log(offsetCardPosition)
      //console.log(offsetCardPosition < window.innerHeight)
      if(offsetCardPosition < window.innerHeight){
        setShowLoginPopup(true)
        window.removeEventListener("scroll", handleScrollEvent)
      }
    }
  }

  const handleScrollEvent = () => {
    if (is_myc_web && !is_myc_secure && !userId) {
      handle_myc_login_popup()
      
    }
  };



  useEffect(() => {
    setShowScrollBtn(false)
    // window.scrollTo(0, 0);
    // window.scroll(0, 0);
    dispatch(setWhatsAppMessage(""));
    
    if (
      is_myc_web &&
      !is_myc_secure &&
      !userId &&
      !showLoginPopup &&
      matrix_data_count_query?.data?.count > 0
    ) {
      handle_myc_login_popup()
      window.addEventListener("scroll", handleScrollEvent);
      return () => window.removeEventListener("scroll", handleScrollEvent);
    } else if (
      is_myc_web &&
      is_myc_secure &&
      !userId &&
      matrix_data_count_query?.data?.count === 0
    ) {
      return navigate("/login");
    } else if (
      is_myc_web &&
      is_myc_secure &&
      userId &&
      matrix_data_count_query?.data?.count === 0 &&
      !is_myc_allow_to_user
    ) {
      set_request_access(true);
    } else {
      set_request_access(false);
      if(is_myc_web && is_myc_secure && userId && matrix_data_count_query?.data?.count > 0){
        dispatch(set_is_allow_access_myc_to_user(true))
      }
      
    }

    // matrixProductsSectionRef.current?.focus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrix_data_count_query?.data?.count, matrix_data_query?.data?.length, layoutView]);

  useEffect(() => {
    if(window.scrollY < 200) return
    ScrollToTopCustom()
}, [searchParams?.page])

  const delayLoad = useDelayLoader(
    !matrix_data_query.data ||
    matrix_data_query?.isFetching ||
    matrix_data_query?.isLoading ||
    matrix_data_query?.isRefetching ||
    matrix_data_query?.fetchStatus !== "idle",
    1500
  )
  
  if(is_myc_web && matrix_data_count_query?.data?.error){
    if(matrix_data_count_query?.data?.error === "Table not present"){
      return <PageNotFound errorMsg={"catalogue is deleted"}/>
    }else{
      return <PageNotFound errorMsg={matrix_data_count_query?.data?.error}/>
    }
  }
  
  
  
  return (
    <section
      {...ArrowKeysReact.events}
      ref={MatrixPageRef}
      tabIndex="1"
      // className={is_myc_web ? 'matrix-page-bottom-padding-for-myc' : ''}
      style={{ outline: "none" }}
    >
      
      <RequestAccessModal show={request_access} />
      {is_myc_web && !userId && !is_myc_secure && (
        // eslint-disable-next-line react/jsx-pascal-case
        <LoginPopUp_for_secure_user_modal showLoginPopup={showLoginPopup} />
      )}
      <FilterSortingSection
        setShowSideBar={setShowSideBar}
        showSideBar={showSideBar}
        is_pagination_active={is_pagination_active}
        is_myc_secure={is_myc_secure}
        page={Number(searchParams?.page) || 0}
        totalProductCount={matrix_data_count_query?.data?.count}
        hasFilterParams={hasFilterParams}
        show_matrix_pagination_at_bottom = {show_matrix_pagination_at_bottom}
      />
      {(show_matrix_pagination_at_both && matrix_data_count_query?.data?.count) || !(show_matrix_pagination_at_bottom && matrix_data_count_query?.data?.count) ? (
        <Pagination
          size="medium"
          clsName={`top-header-mobile-view-pagination d-md-none d-flex position-sticky`}
          page={Number(searchParams?.page) || 0}
          totalProductCount={matrix_data_count_query?.data?.count}
          
        />
      ) : null}
     

      <div
        className={`mt-5 pt-3 matrix-products-section ${
          showScrollBtn ? "" : "pb-force-5"
        } d-flex`}
        ref={matrixProductsSectionRef}
        >
        
        {!show_web_inline_matrix_filter_option ? <FilterSideBarOptions
          showSideBar={true}
          setShowSideBar={setShowSideBar}
          clsName={"position-sticky d-lg-block d-none static-filter-sidebar"}
          isMatrixSilder={true}
          setHasFilterParams={setHasFilterParams}
          
        /> : null}

        <section className="ec-page-content matrix-card-container">
          <div className="container-fluid">
          {is_myc_web ? (
                    // <div className="myc_catelogue_title">
                    <div className="product_title">
                      <h2>{catelogue_title}</h2>
                    </div>
                  ) :  matrix_data_count_query?.status === "success" ? <ProductNameHeader count={matrix_data_count_query?.data?.count}/> : null}
                  
            {show_matrix_branding_banner ? <MatrixBranding/> : null}
            {/* { matrix_data_query?.isLoading || matrix_data_query?.isFetching || !matrix_data_query.data ? ( */}
            {!matrix_data_query.data ||
            matrix_data_query?.isFetching ||
            matrix_data_query?.isLoading ||
            matrix_data_query?.isRefetching ||
            delayLoad ||
            matrix_data_query?.fetchStatus !== "idle" ? (
              <Loader isLoading={true} height={500} style={{position: "relative", top: "200px"}}/>
            ) : matrix_data_query?.data?.length > 0 && matrix_data_query?.fetchStatus === "idle" ? (
              <div className="row">
                <div className="ec-shop-rightside col-lg-12 col-md-12">
                  
                  <div className="shop-pro-content">
                    <div className="shop-pro-inner">
                      <div className="row">
                        {is_pagination_active && (
                          <div onLoad={(e) => {
                            const cotainer_details = e.target.getBoundingClientRect()
                            setShowScrollBtn(cotainer_details?.bottom > window.innerHeight)
                          }}>
                            <div className="row p-2"
                            onLoad={() => {
                              if( window.innerWidth < 576 && prevPageRef.current !== searchParams.page){
                                  
                                  prevPageRef.current = searchParams.page
                              }
                            }}
                            >
                              {Array.isArray(matrix_data_query?.data) && matrix_data_query?.data?.map((item, index) => {
                                return (
                                  item && (
                                    <div
                                      key={index}
                                      id={
                                        is_pagination_active
                                          ? Number(searchParams.page || 0) *
                                              Number(defaultData) +
                                            index
                                          : index
                                      }
                                      // className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-6 mb-6 pro-gl-content product-div matrix-products"
                                      className={`mb-6 pro-gl-content product-div matrix-products responsive-matrix-card ${layoutViewClass} ${img_type_six ? "img-type-six" : ""} ${show_web_inline_matrix_filter_option ? "full-width-matrix" : ""}`}
                                    >
                                      <ProductNew
                                        index={
                                          is_pagination_active
                                            ? Number(searchParams.page || 0) *
                                                Number(defaultData) +
                                              index
                                            : index
                                        }
                                        item={item}
                                        sort={searchParams?.sort || 0}
                                        saveStateToLocalStorage={
                                          saveStateToLocalStorage
                                        }
                                        matrix_data_count ={ matrix_data_count_query.data?.count || 0}
                                      />
                                    </div>
                                  )
                                );
                              })}
                            </div>
                           
                            {/* {currProd && matrix_data_query?.data?.length > 0 ? <ScrollToAnchor/> : null} */}
                            {showScrollBtn ? <ScrollBtn /> : null}
                          </div>
                        )}
                      </div>
                      
                    </div>
                  </div>
                  {(show_matrix_pagination_at_both && matrix_data_count_query?.data?.count) || (show_matrix_pagination_at_bottom && matrix_data_count_query?.data?.count) ? (
        <Pagination
          size="large"
          clsName={`bottom-pagination d-flex position-sticky`}
          page={Number(searchParams?.page) || 0}
          totalProductCount={matrix_data_count_query?.data?.count}
          
        />
      ) : null}
                  {/* {is_pagination_active && <Pagination/>} */}
                </div>

                <div
                  className="filter-sidebar-overlay my-auto filter-sidebar-section-overlay"
                  style={
                    showSideBar ? { display: "block" } : { display: "none" }
                  }
                ></div>
                
              </div>
            ) : (
              <>
              {!matrix_data_count_query.data?.count && <NoProduct
                errMsg={"No Data Found"}
                showLoader={true}
                hideHomeBtn={true}
                showLoginPopup={showLoginPopup}
                request_access={request_access}
              />}
              </>
            )}
            <FilterSideBarOptions
                  showSideBar={showSideBar}
                  setShowSideBar={setShowSideBar}
                  setHasFilterParams={setHasFilterParams}
              />
          </div>
        </section>
      </div>
      {/* <WhatsappIconNew /> */}
    </section>
  );
};

export default MatrixViewNew;
