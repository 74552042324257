import React from 'react'

import { BarLoader, BeatLoader } from "react-spinners"
import { get_property_settings } from './Helper'


const Loader = ({ isLoading, size, clsName, loaderType = null, height= null, style={}}) => {
    // const styles = {
    //     minHeight: '40vh',
    //     display: 'flex',

    // }
    
    const primaryColor = get_property_settings('btn_primary_color') || "rgb(238, 85, 39)"
    function LoaderType ({loaderType}) {
        
        switch(loaderType) {
            case "BeatLoader" : return <BeatLoader color={primaryColor} />; 
            default : return <BarLoader color={primaryColor} height={6} width={300} />;
        }
    }
    
    // useLayoutEffect(() => {
        
    //    if(!location.pathname.includes("/product-page") || !document.getElementById("ec-main-menu-desk")) return
    //    document.getElementById("ec-main-menu-desk")?.scrollIntoView()
    // }, [location.pathname])
    
    
    return (
        isLoading  ?
        (<div
            className={`${size === "sm" ? "sm-loader loader" : "loader"} ${clsName ? clsName : ""}`}
        >

            {/* <img src={loader} alt="Loading..."></img> */}

            {/* React loader Here */}
            <div style={{ display: 'flex', alignItems: "center", paddingTop: '150px', paddingBottom: '300px', minHeight: height ? height +'px':'40vh', flexDirection: 'column', gap: '0.5em', ...style }}> 
                <p style={{ color: primaryColor, fontSize: '1.6rem' }}>Loading...</p>
                <LoaderType loaderType={loaderType}/>
                {/* <BeatLoader color={primaryColor} /> */}
            </div>
        </div>) : null
    )
}

export default React.memo(Loader)