import React from 'react'
import { get_property_settings } from '../../Helper'


function FooterCopy() {
    const text_besides_copyright_on_web = get_property_settings("text_besides_copyright_on_web")
    
    return (
        <div className="footer-bottom">
            <div className="container-fluid">
                <div className="row align-items-center">
                    {/* -- Footer Copyright Start -- */}
                    <div className="col footer-copy">
                        <div className="footer-bottom-copy ">
                            <div className="ec-copy">
                                <span>
                                    Copyright © {new Date().getFullYear()}.{" "}{text_besides_copyright_on_web}
                                </span>
                                <span>
                                    Powered by <a href="http://triologic.com/" target="_blank" rel="noopener noreferrer">Triologic Enterprises Pvt. Ltd.</a>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FooterCopy
