import React from "react";
import { sentenceCase } from "../../../utils";

function GSTField({formParams, clsName = "",  errors, register}) {
  const {cin_label, cin_required, cin_max_length, cin_min_length, cin_regex} = formParams || {};
  const short_code= "gst_no";
  return (
    <span className={clsName}>
      <label className="label">
        {cin_label}
        {cin_required === "1" && <span className="required-field">*</span>}
      </label>

      <input
        // tabIndex={i + 1}
        {...register(short_code, {
          required: {
            value: cin_required === "1",
            message: `${cin_label} is required`
          },
          minLength: {
            value: parseInt(cin_min_length),
            message: "Invaild GST No"
          },
          maxLength: {
            value: parseInt(cin_max_length),
            message:  "Invaild GST No"
          },
          pattern: {
            value: `/${cin_regex}/`,
            message: "Invaild GST No"
          },
          
          
        })}
        className="RegInp fieldValue"
        type={"text"}
        name={short_code}
        placeholder={`Enter ${cin_label}`}
        
        
      />
      <div className="required-field">
        {errors[short_code]?.message || ""}
      </div>
    </span>
  );
}

export default GSTField;
