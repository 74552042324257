import React, { useCallback, useEffect, useState } from 'react'
import {
    closeMenuWhenClickedOutside,
    get_informationFromSessionID,
    get_userData_from_activeUser,
} from '../../Helper';
import BrowseByCategory from './BrowseByCategory'

import {useSelector} from "react-redux"
import { get_is_myc_web, getUserDetails } from '../../../redux/Reducer/reducer'

function HeaderTop({setOpenMobileMenu, clsName}) {
    const [showCurrency, setShowCurrency] = useState(false)
  const is_myc_web = useSelector(get_is_myc_web)
  

    const userIDState = useSelector(getUserDetails)
    
    const userID = get_informationFromSessionID("userID") || userIDState?.userID
   
   const showBrowseByCategory = userID &&  get_userData_from_activeUser().show_design === "Yes" && get_userData_from_activeUser().show_inventory === "Yes"
   
   
   const handleMouseClick = useCallback((e)=>{
        closeMenuWhenClickedOutside(e, showCurrency, setShowCurrency, ['dropdown-toggle', 'dropdown-item', 'eci-caret-down'])
   }, [showCurrency])
    
    useEffect(()=>{
       
        window.addEventListener('click', handleMouseClick)
        return () => {
            window.removeEventListener('click',  handleMouseClick)
        }
    }, [showCurrency, handleMouseClick])
    
    if(!showBrowseByCategory || is_myc_web){
        return null
    }
    
   
    return (
        <>
      
        <div className={`header-top ${clsName} d-none d-lg-block`}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-7 header-top-right d-none d-lg-flex justify-content-center m-auto">
                        <div className="header-top-lan-curr d-flex justify-content-end align-items-center">
                            <BrowseByCategory/>
                         </div>
                    </div>
                   
                </div>
            </div>
        </div>
        </>
    )
}

export default React.memo(HeaderTop)
