import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./MUIDeleteModal.css"

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min(400px, 90%)",
    // bgcolor: "background.paper",
    bgcolor: "var(--named_wishlist_popup_bg_color, #fff)",
      border: '1px solid var(--named_wishlist_popup_hairline_color, #eee)',
      borderRadius: '0.3rem',
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function MUIDeleteModal({item_name, openMUIdeleteModal, handleCloseMUIdeleteModal, handle_delete}) {
  return (
    <Modal
    open={openMUIdeleteModal}
    onClose={handleCloseMUIdeleteModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="mui-delete-modal"
  >
    <Box sx={style}>
        <section className="delete-section">
            <header>
                <p><b>Are you Sure ?</b></p>
                <p>You want to delete "{item_name}"</p>
            </header>

            <div className="btns">
                <button onClick={handle_delete}>Yes</button>
                <button onClick={handleCloseMUIdeleteModal}>No</button>
            </div>
        </section>
    </Box>
    </Modal>
  )
}

export default MUIDeleteModal