import React, { useEffect, useState } from "react";
import { fetch_data_from_api_from_query } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import { useDispatch, useSelector } from "react-redux";
import { getCatMaster, setCatMaster } from "../../../redux/Reducer/reducer";
import {useNavigate} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { get_informationFromSessionID, get_userData_from_activeUser, set_informationToSessionID } from "../../Helper";
import { get_search_params } from "../../../utils";

function BrowseByCategory() {
  const [categoryButtons, setCategoryButtons] = useState([]);
  const selectedCategory = useSelector(getCatMaster);
  
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const searchParams = get_search_params()

  const master_setting_query = useQuery({
    queryKey: ["master_setting"],
    queryFn: () => fetch_data_from_api_from_query(`${ApiList.setting}/${ApiList.master_setting}`, "master_setting"),
  });



  useEffect(()=>{
    if(Array.isArray(master_setting_query?.data)){

      const userID = get_informationFromSessionID("userID")

      if(!userID) return;


      const userData = get_userData_from_activeUser()

      let show_design =  userData?.show_design
      show_design = show_design === "Yes"

      let show_inventory =  userData?.show_inventory
      show_inventory = show_inventory === "Yes"

      setCategoryButtons(master_setting_query?.data);
      if(show_design && show_inventory){
        const selected_master = searchParams?.selected_master || get_informationFromSessionID("selected_master")
        if(selected_master){
          dispatch(setCatMaster(selected_master));
        }else{

          let master = master_setting_query?.data.find(
            (master) => master.position === "1"
          )?.short_code;
          master = master.replace("_online", "");
          dispatch(setCatMaster(master));
        }
      }
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [master_setting_query?.isSuccess])

  if(master_setting_query?.data?.length === 1){
    return null
  }
  
  return (
    <div className="header-top-login-reg category-header-top-btns">
      {categoryButtons.map((button) => {
        const short_code = button.short_code.replace("_online", "");
        const selected = short_code === selectedCategory;
        // const selected = short_code === selectedBtn;
        
        return (
          <button
            value={short_code}
            key={button?.position}
            className={selected ? "selected" : "no-selected"}
            onClick={(e)=> {
                navigate('/home')
                dispatch(setCatMaster(short_code))
                set_informationToSessionID({selected_master: short_code})
            }}
          >
            {button?.custom_label}
          </button>
        );
      })}
    </div>
  );
}

export default React.memo(BrowseByCategory);
