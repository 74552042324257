import React, { useEffect, useRef } from "react";
import {
  convertToGrams,
  get_property_settings,
} from "../../../Component/Helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { get_selected_currency, setCart } from "../../../redux/Reducer/reducer";
import {
  total_cart_products_cost,
  total_piece,
  total_quantity,
} from "./CartHelper";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";
import { getCustomValue } from "../../../Custom/CompanyList";
import { formatPrice } from "../../../Component/HomeComponents/HomeHeader/components/UserProfileDropDownMenu/Components/ChangeCurrencyModal/change_currency_helper";

import MUISideBar from "../../../Component/MUISideBar/MUISideBar";
import CheckoutNew from "../../Checkout/CheckoutNew";

function CartFooter({ moveAll, type_of_cart, cartData }) {
  const navigate = useNavigate();
  const confirm_order_button_text = get_property_settings(
    "confirm_order_button_text"
  );
  const footerData = useSelector((state) => state?.reducer?.cart);
  const dispatch = useDispatch();
  const cartFooterRef = useRef(null);
  const selected_currency = useSelector(get_selected_currency);
  useEffect(() => {
    if (cartFooterRef?.current) {
      sessionStorage.setItem(
        "cartFooterHeight",
        cartFooterRef?.current?.offsetHeight
      );
    }
    if (!cartData) return;
    dispatch(
      setCart({
        ...footerData,
        total_cost: total_cart_products_cost(cartData),
        total_quantity: total_quantity(cartData),
        total_piece: total_piece(cartData),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData]);

  const show_total_in_cart_footer = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.show_total_in_cart_footer
  );
  const hide_elements_from_cart_footer = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.hide_elements_from_cart_footer
  );
  const show_pcs_sets_in_cart_footer = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_pcs_sets_in_cart_footer)

  const show_net_wt =
    hide_elements_from_cart_footer &&
    !hide_elements_from_cart_footer?.includes("net_wt");

  const show_finalise_fields = get_property_settings("show_finalise_fields");
 
  const show_shipping_billing_section = get_property_settings(
    "show_shipping_billing_section"
  );
  const redirect_to_billing =
    show_finalise_fields === "No" && show_shipping_billing_section === "Yes";
  const show_checkout_modal = redirect_to_billing ? false : true;
  const open_checkout_modal = useRef(null);

  return (
    <div className="cart-footer py-2" ref={cartFooterRef}>
      <MUISideBar
        width={"min(400px, 100%)"}
        ref={open_checkout_modal}
        title="Confirm Order"
        className="checkout-side-modal"
      >
        <CheckoutNew />
      </MUISideBar>
      
      <div className="container-fluid">
        <div className="details">
          <div className="row">
            <div className="col-6  text-left">
              <div className="row">
                <div className="col-6 pr-0 cart-footer-label">Gross Wt:</div>
                <div className="col-4 p-0 cart-footer-value">
                  {footerData?.total_gross_wt
                    ? convertToGrams(footerData?.total_gross_wt)
                    : "-"}
                </div>
              </div>
              {show_net_wt ? (
                <div className="row">
                  <div className="col-6 pr-0  cart-footer-label">Net Wt:</div>
                  <div className="col-4 p-0 cart-footer-value">
                    {footerData?.total_net_wt
                      ? convertToGrams(footerData?.total_net_wt)
                      : "-"}
                  </div>
                </div>
              ) : null}
              {show_total_in_cart_footer ? (
                <div className="row">
                  <div className="col-6 pr-0  cart-footer-label">Total:</div>
                  <div className="col-4 p-0 cart-footer-value">
                    {" "}
                    {footerData?.total_cost
                      ? `${formatPrice(
                          footerData?.total_cost,
                          true,
                          selected_currency
                        )}`
                      : "-"}
                  </div>
                </div>
              ) : null}
              {show_pcs_sets_in_cart_footer ? (
                <>
                <div className="row">
                  <div className="col-6 pr-0  cart-footer-label">Sets:</div>
                  <div className="col-4 p-0 cart-footer-value">
                    {" "}
                    {footerData?.total_quantity
                      ? `${footerData?.total_quantity}`
                      : "-"}
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 pr-0  cart-footer-label">Pcs:</div>
                  <div className="col-4 p-0 cart-footer-value">
                    {" "}
                    {footerData?.total_quantity
                      ? `${footerData?.total_piece}`
                      : "-"}
                  </div>
                </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-6 pr-0  cart-footer-label">Pcs:</div>
                  <div className="col-4 p-0 cart-footer-value">
                    {" "}
                    {footerData?.total_quantity
                      ? `${footerData?.total_quantity}`
                      : "-"}
                  </div>
                </div>
              )}
            </div>
            <div className="col-6 pl-0 d-flex justify-content-end ">
              <div className="chekout-btn d-flex align-self-center">
                <div
                  className="btn-li ui button-ui me-0 px-sm-3"
                  onClick={() => {
                    if (type_of_cart === "temp_wishlist") {
                      moveAll();
                    } else {
                      if (show_checkout_modal) {
                        if (open_checkout_modal.current) {
                          open_checkout_modal.current?.open_side_modal("right");
                          
                        }
                      } else {
                        navigate("/checkout", {
                          state: {
                            amount: formatPrice(
                              footerData?.total_cost,
                              false,
                              selected_currency
                            ),
                          },
                        });
                      }
                    }

                    // PlaceOrder(company_code, userID);
                  }}
                >
                  {type_of_cart === "temp_wishlist"
                    ? "Move All to Cart"
                    : confirm_order_button_text}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartFooter;
