import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import HeaderUserMenu from "../HeaderUserMenu";
import { get_is_myc_web } from "../../../../redux/Reducer/reducer";
import { useSelector } from "react-redux";
import {
  get_informationFromSessionID,
  get_userData_from_activeUser,
} from "../../../Helper";
import SearchModal from "../../../SearchModal/SearchModal";
import { useLocation } from "react-router-dom";
import CustomOrderFilterOption from "../../../../Pages/CustomOrder/Components/CustomOrderFilterOption/CustomOrderFilterOption";

function SearchCartMenuOpt() {
  const [showSearch, setShowSearch] = useState(false);
  const is_myc_web = useSelector(get_is_myc_web);
  const userID = get_informationFromSessionID("userID");
  const location = useLocation();
  //For Filter Option
  const user_data = get_userData_from_activeUser() || {};
  const isAdmin = user_data?.user_type === "14" || user_data?.user_type === "1";
  const showFilter = location.pathname === "/customer-order" && isAdmin;

  if (window.scrollY < 40) {
    return null;
  }

  return (
    <section className="search_and_cart_opt main-header d-flex">
      <section className="search-modal-container">
        {!is_myc_web && showSearch && (
          <SearchModal showSearch={showSearch} setShowSearch={setShowSearch} />
        )}
      </section>
      {!is_myc_web && userID ? (
        <div className="align-self-center search">
          {showFilter ? (
            
            <CustomOrderFilterOption />
          ) : (
            <div
              className="ec-header-bottons header-search-icon-menu"
              onClick={() => setShowSearch(true)}
            >
              <FaSearch />
            </div>
          )}
        </div>
      ) : null}
      <div className="align-self-center">
        <div className="ec-header-bottons d-none d-lg-flex">
          <HeaderUserMenu />
        </div>
      </div>
    </section>
  );
}

export default SearchCartMenuOpt;
