import React from "react";
import Stepper from "./components/Stepper";
import Remarks from "./components/Remarks"
import { getCustomValue } from "../../../../../../Custom/CompanyList";
import SetsStepper from "./components/SetsStepper";
import { useSelector } from "react-redux";
import { get_fsv_selected_product, getCatMaster } from "../../../../../../redux/Reducer/reducer";
import { useLocation } from "react-router-dom";
import { get_bulk_settings } from "../../../../../../Component/Helper";

function Qty_Remark({clsName = ""}) {
  
  const hide_elements = getCustomValue("hide_elements")?.includes("Qty_Remark")
  const selectedProduct = useSelector(get_fsv_selected_product);
  let enable_set_and_pcs_logic = selectedProduct.enable_set_and_pcs_logic;
  enable_set_and_pcs_logic = enable_set_and_pcs_logic === "1"
  const master = useSelector(getCatMaster)
  let {show_fsv_remarks, show_remarks_in_inventory} = get_bulk_settings(["show_fsv_remarks", "show_remarks_in_inventory"])
  show_fsv_remarks = show_fsv_remarks === "Yes";
  show_remarks_in_inventory = show_remarks_in_inventory === "Yes";
  const show_remark = master === "inventory_master" && show_remarks_in_inventory || show_fsv_remarks

  const location = useLocation()
  const cartData = location.state?.cartData || {}
  if(hide_elements){
    return null
  }
  
  let cartCount = null;
  let cartSetCount = null;
  if(enable_set_and_pcs_logic){
    cartSetCount = cartData?.quantity
    cartCount = cartData?.piece
  }else{
    cartCount = cartData?.quantity
  }
  
  

   return (
    <div className={`ec-pro-variation-inner ec-pro-variation-size ${clsName}`}>
      
        {enable_set_and_pcs_logic && <SetsStepper selectedProduct={selectedProduct} cartCount={cartSetCount}/>}
        <Stepper selectedProduct={selectedProduct} cartCount={cartCount}/>
      
      {show_remark && <Remarks cartRemark={cartData?.remarks}/>}
    </div>
  );
}

export default Qty_Remark;
