import CardSectionHeader from "../HomeComponents/CardHeader/CardHeader";
import styles from "../../CSS/HomeCSS/CardSection.module.css";
import NoImg from "../../assets/images/no_image_available.png";
import { get_bulk_settings, get_property_settings } from "../Helper";

import SingleCardNew from "./SingleCardNew";
import React, { useEffect } from "react";
import ReactSlider from "../ReactSlider/ReactSlider";
import { set_equal_slick_slider_card_height } from "../../utils";



function CategoriesSectionNew(props) {

  //const companyCode = get_informationFromSessionID("companyCode")
  const homescreen_category_display_style = get_property_settings(
    "homescreen_category_display_style"
  );
  const minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web =
    get_property_settings(
      "minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web"
    );
  const minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web_mobile =
    get_property_settings(
      "minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web_mobile"
    );

    const responsive = [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: parseInt(minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: parseInt(minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web),
          slidesToScroll: 1,
        },
      },
  
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: parseInt(minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web),
          slidesToScroll: 1,
        },
      },
  
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: parseInt(minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: parseInt(minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web_mobile),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: parseInt(minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web_mobile),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: parseInt(minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web_mobile),
          slidesToScroll: 1,
        },
      },
    ];

    useEffect(() => {
      if(homescreen_category_display_style === "minibgimg_nllefttitle_nlleftcount_horizontalscroll" && props.index){
        set_equal_slick_slider_card_height(`.categorySection-${props.index} .minibgimg_nllefttitle_nlleftcount_horizontalscroll`)
      }
    }, [props.index, homescreen_category_display_style])

  if (typeof props.categoriesData !== "object") return null;
  const { cdn, default_image } =
    get_bulk_settings(["cdn", "default_image"]) || {};
  const defaultImg = default_image
    ? `${cdn}uploads/default_image/${default_image}`
    : NoImg;
  // const defaultImg = companyCode === "VEdD" ? default_cat_img_tgc : companyCode === "S0FMQVNIQU9OTElORQ" ? default_cat_img_kalashonline : NoImg;

  const show_header = props.categoriesData?.some(
    (category) => category?.show_homescreen === "1"
  );

 

  return (
    <section className={`categorySection ${props.index ? `categorySection-${props.index}` : ''}`}>
      <div
        className={`container-fluid ${styles["cards-container"]} homeCardSection`}
      >
        {show_header && <CardSectionHeader {...props} />}
        <div className={`row ${styles["cards-section"]} category-card`}>
          {homescreen_category_display_style ===
          "minibgimg_nllefttitle_nlleftcount_horizontalscroll" ? (
            <ReactSlider
              additionalSetting={{
                infinite: false,
                slidesToScroll: "1",
                slidesToShow:
                  window.innerWidth > 768
                    ? parseInt(minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web)
                    : parseInt(minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web_mobile),
              }}
              className={
                `${
                  props.categoriesData?.length >
                    parseInt(
                      minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web
                    ) ||
                  props.categoriesData?.length >
                    parseInt(
                      minibgimg_nllefttitle_nlleftcount_horizontalscroll_width_divider_web_mobile
                    )
                }`
                  ? "no-silck-margin trending-slick"
                  : "trending-slick"
              }
              responsive={responsive}
            >
              {props.categoriesData.map((category) => {
                const img = category?.category_img
                  ? `${cdn}uploads/branding_image/category/${category.category_img}`
                  : defaultImg;
                if (category?.show_homescreen === "0") return null;
                return (
                  <SingleCardNew
                    img={img}
                    key={category.id}
                    cat_id={category.id}
                    cat_name={category.cat_name}
                    product_count={category.product_count}
                    hasSubcategory={
                      Array.isArray(category.subcategory) &&
                      category.subcategory.length > 0
                    }
                    setShowCategoryModal={props.setShowCategoryModal}
                    layoutStyle={homescreen_category_display_style}
                    hasImage={!!category?.category_img}
                    textColor={category?.branding_text_fg_color}
                    bgColor={category?.branding_text_bg_color}
                    categoryData={category}
                  />
                );
              })}
            </ReactSlider>
          ) : (
            props.categoriesData.map((category) => {
              const img = category?.category_img
                ? `${cdn}uploads/branding_image/category/${category.category_img}`
                : defaultImg;
              if (category?.show_homescreen === "0") return null;
              return (
                <SingleCardNew
                  img={img}
                  key={category.id}
                  cat_id={category.id}
                  cat_name={category.cat_name}
                  product_count={category.product_count}
                  hasSubcategory={
                    Array.isArray(category.subcategory) &&
                    category.subcategory.length > 0
                  }
                  setShowCategoryModal={props.setShowCategoryModal}
                  layoutStyle={homescreen_category_display_style}
                  hasImage={!!category?.category_img}
                  textColor={category?.branding_text_fg_color}
                  bgColor={category?.branding_text_bg_color}
                  categoryData={category}
                />
              );
            })
          )}
        </div>
      </div>
    </section>
  );
}

export default React.memo(CategoriesSectionNew);
