import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_is_myc_web,
  get_myc_is_secured,
  setConstants,
  setIsLoggedIn,
  set_inCartWishList,
  set_is_allow_access_myc_to_user,
  set_matrixViewLayout,
} from "./redux/Reducer/reducer";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ApiList} from "./Api/ApiList";
import { delete_informationFromSessionID,  get_informationFromSessionID, get_property_settings, get_userData_from_activeUser, logOutUser, set_activeUser, set_informationToSessionID, set_userData_to_activeUser } from "./Component/Helper";
import { fetch_data_from_api, fetch_data_from_api_from_query} from "./Api/APICalls";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import WhatsappIconNew from "./Component/WhatsappIcon/WhatsappIconNew";
import { Layout } from "./utils";



const ProtectedRoutes = ({ children }) => {
 
  
  const location = useLocation()
  const is_myc_web = useSelector(get_is_myc_web);
  const is_myc_secure = useSelector(get_myc_is_secured);
  
  const userID = get_informationFromSessionID("userID") || "";
  const company_code = get_informationFromSessionID("companyCode");
  const user_details = (userID && get_userData_from_activeUser()) || {}
  let login_on_startup_online = get_property_settings("login_on_startup_online")
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [enable_wishlist, set_enable_wishlist] = useState(false)
  const constants = useSelector((state) => state?.reducer?.constants);
 
  

  login_on_startup_online = login_on_startup_online === "Yes" && !userID
  
  const dispatch = useDispatch();

  async function fetch_cart_count(){

    const show_named_wishlist_app = get_property_settings("show_named_wishlist_app")
    const enable_shortlist = get_property_settings("enable_shortlist")
    
    const cart_count_Response = await fetch_data_from_api(`${ApiList.cart}/${ApiList.cartCount}`, 'cart-count', {company_code, user_id: userID, table: "temp_cart"})
    dispatch(
      setConstants({
        companyCode: company_code,
        userId: userID,
        count: cart_count_Response,
      })
    );

    set_enable_wishlist(enable_shortlist === "Yes" && show_named_wishlist_app === "Yes")
}


useQuery({
  queryKey: ["login", userID],
  queryFn: () =>
  fetch_data_from_api_from_query(
    ApiList?.login,
    "login",
    {
      login_from: "web",
      password: atob(user_details?.password || ""),
      mobile_no: user_details?.mobile_no,
      long: 0,
      lat: 0,
      device_token: "",
      device_model: "",
      device_ip: "",
      device_id: "",
    }
  ).then(res => {
    
    if(res.ack === "1"){
      set_userData_to_activeUser(res);
      set_activeUser();
     
      dispatch(set_inCartWishList({
        in_cart_id_dm: res.in_cart_id_dm,
        in_cart_id_im: res.in_cart_id_im,
        in_wishlist_id_dm: res.in_wishlist_id_dm,
        in_wishlist_id_im: res.in_wishlist_id_im,
      }))
    }else{

      // dispatch(set_inCartWishList({
      //   in_cart_id_dm: [],
      //   in_cart_id_im: [],
      //   in_wishlist_id_dm: [],
      //   in_wishlist_id_im: [],
      // }))

      if(res.ack === "3" || res.ack === "0"){

          
          queryClient.invalidateQueries({ queryKey: ['category'] })
          queryClient.invalidateQueries({ queryKey: ['homescreen'] })
          logOutUser()
          if(res.ack === "3"){

            set_informationToSessionID({isUserLoggedOut: true});
          }
          dispatch(
            setConstants({})
          );
          dispatch(setIsLoggedIn({status: false, userID: ""}))
          dispatch(set_inCartWishList(null))
          dispatch(set_is_allow_access_myc_to_user(false))
          if(is_myc_web){
            const rs = get_informationFromSessionID("rs")
            navigate(`${rs ? `/?rs=${rs}` : '/'}`)
          }else{
            navigate('/');
          }
         
      
      }
      
    }
    return res
  }),
  // enabled: !!(userID && !inCartWishList),
  enabled: !!userID,
});

useQuery({
  queryKey: ["named_wishlist_initial"],
  queryFn: () =>
    fetch_data_from_api_from_query(ApiList.get_named_wishlist_list, "named_wishlist", {
      product_id: "0"
    }).then(response => {
      
      if(Array.isArray(response) && response.length > 0){
        const shortlist_count = response.reduce((total, value) => {
          total += Number(value?.product_count) || 0;
          return total
        }, 0)

        dispatch(setConstants({...constants, count: {...constants.count, shortlist_count}}))
      }
      return response
    }
  ),
    enabled: enable_wishlist
});



useEffect(() => {
  //set default view layout 
  const default_image_type_mycat_v2_mobile = get_property_settings("default_image_type_mycat_v2_mobile")
   const default_Layout_style = Layout.find(layout => layout.layout_style === default_image_type_mycat_v2_mobile)
    dispatch(set_matrixViewLayout(default_Layout_style?.type || "grid-layout"))
  
  fetch_cart_count()

// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  
  //Clear filter params in all routes expect (matrix and FSV)
  if(location.pathname !== "/matrix-page" && !location.pathname.includes('/product-page')){
    delete_informationFromSessionID("filterParams")
  }

  const isCartOrShortList = location.pathname.includes('/cart') || location.pathname.includes('/short-list');
  

  if((!is_myc_web && login_on_startup_online) || (isCartOrShortList && !userID)){
    return <Navigate to="/login"/>
  }
  
  
    //Code for MYC 
  if(is_myc_web && location.pathname === "/home"){
        if(!is_myc_secure || (is_myc_secure && userID)){
            return <Navigate to="/matrix-page"/>
        }else{
          return <Navigate to="/login"/>
        }
  }else if(is_myc_web && is_myc_secure && !userID){
      return <Navigate to="/login"/>
  }

  else if(is_myc_web && (location.pathname === "/cart" || location.pathname === "/short-list" || location.pathname === "/checkout" || location.pathname.includes("/product-page")) && !userID){
        return <Navigate to="/login"/>
  }

  

    
   
    return (
      <>
      {children}
      <WhatsappIconNew/>
      </>
    );
  
};

export default ProtectedRoutes;
