import { useFormContext, Controller } from "react-hook-form";
import Select, {components} from "react-select";
import AddFormModal from "../../../Checkout/Components/DropDown/Components/AddFormModal/AddFormModal";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import MUISideBar from "../../../../Component/MUISideBar/MUISideBar";
import AddRetailerForm from "../../../../Component/AddRetailerForm/AddRetailerForm";
import { get_property_settings } from "../../../../Component/Helper";
import { customReactSelectHTMLFilter } from "../../../../utils";

function SelectDropDownInputWithButton({ field, data }) {
  const { field_name, short_code, required_field } = field || {};
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const [showFormModal, setShowFormModal] = useState(false);
  const retailer_slider_modal_ref = useRef(null);
  const primary_BG_color = get_property_settings("btn_primary_color");
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  const distributor_client_master_id = watch("distributor")?.value || "";
  useEffect(() => {
    if (!data || data?.length === 0) return;
    if (searchParams?.retailer_id) {
      const retailer = data.find(
        (obj) => obj.value === searchParams?.retailer_id
      );
      setValue(short_code, retailer);
      retailer_slider_modal_ref.current?.close_side_modal()
    } else {
      // setValue(short_code, data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchParams?.retailer_id]);


  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        {props.data.label}
      </components.Option>
    )
  }

  const CustomSingleValue = (props) => {
    return (
      <components.SingleValue {...props}>
        {props.data.text}
      </components.SingleValue>
    )
  }

  return (
    <>
      <MUISideBar
        width={"min(400px, 100%)"}
        ref={retailer_slider_modal_ref}
        title="Add retailer"
      >
        <AddRetailerForm distributor_client_master_id={distributor_client_master_id}/>
      </MUISideBar>
      {showFormModal && (
        <AddFormModal
          formHeader="Add Retailer"
          showFormModal={showFormModal}
          setShowFormModal={setShowFormModal}
          watch={watch}
        />
      )}
      <div className="form-input-group">
        <label htmlFor={short_code}>
          <span>{field_name}</span>
          {required_field === "1" && <span className="required">*</span>}
        </label>
        <div className="two-col">
          <Controller
            control={control}
            rules={{
              required: {
                value: required_field === "1",
                message: `${field_name} is required`,
              },
            }}
            name={short_code}
            render={({ field }) => (
              <Select
                components={{Option: CustomOption, SingleValue: CustomSingleValue}}
                getOptionLabel={(option) => option.text}

                className="react-select-container r-container"
                classNamePrefix="react-select r-select"
                id={short_code}
                options={data}
                filterOption={customReactSelectHTMLFilter}
                {...field}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25:
                      (primary_BG_color &&
                        primary_BG_color
                          .replace("rgb", "rgba")
                          .replace(")", ",0.2)")) ||
                      "inherit",
                    primary: primary_BG_color,
                  },
                })}
              />
            )}
          />
          
          {/* <button className="add-btn" type="button" onClick={() => setShowFormModal(true)}>Add</button> */}
          <button
            type="button"
            className="add-btn ui button-ui"
            onClick={() => {
              if (!retailer_slider_modal_ref.current) return;
              retailer_slider_modal_ref.current?.open_side_modal("right");
            }}
          >
            Add
          </button>
        </div>
        {errors[short_code] && (
          <p className="error-input">{errors[short_code]?.message}</p>
        )}
      </div>
    </>
  );
}

export default SelectDropDownInputWithButton;
