import { TbFilter, TbFilterCheck  } from "react-icons/tb";
import MUISideBar from "../../../../Component/MUISideBar/MUISideBar";
import { useCallback, useMemo, useRef } from "react";
import CustomOrderFilterForm from "./Components/CustomOrderFilterForm/CustomOrderFilterForm";
import { useLocation } from "react-router-dom";
import { get_search_params } from "../../../../utils";
import { FaFilter } from "react-icons/fa";


function CustomOrderFilterOption({iconClassName = ""}) {
  const handle_filter_modal = useRef(null);
  const close_filter_modal  = useCallback(() => {
    if (handle_filter_modal.current) {
      handle_filter_modal.current?.close_side_modal("right");
      
    }
  }, [])

  
  const searchParams_Short_Code_To_Check = [
    "order_no",
    "filter_distributor_id",
    "filter_retailer_id",
    "vendor_id"
  ]

  const location = useLocation()
  const searchParams = get_search_params()
  const isSeachPramsEmpty = useMemo(() => {
    return searchParams_Short_Code_To_Check.every(sc => !searchParams[sc])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])
  const isfilterApplied = (!isSeachPramsEmpty && location.state?.filter_applied) || false

  return (
    <>
      <MUISideBar
        width={"min(400px, 100%)"}
        ref={handle_filter_modal}
        title="Filter"
        className="custom-order-filter-form-modal"
      >
        <CustomOrderFilterForm close_filter_modal={close_filter_modal}/>
      </MUISideBar>
    <div  className={`ec-header-bottons filter-icon ${isfilterApplied ? "checked" : ""}`}
      onClick={() => {
        if (handle_filter_modal.current) {
          handle_filter_modal.current?.open_side_modal("right");
          
        }
      }}
    >
       <FaFilter className={iconClassName}/>

    </div>
    </>
  )
}

export default CustomOrderFilterOption
