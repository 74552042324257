import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import { RiEdit2Fill } from "react-icons/ri";
import { CgPlayListAdd } from "react-icons/cg";
import { fetch_data_from_api } from "../../../Api/APICalls";
import { ApiList } from "../../../Api/ApiList";
import { get_property_settings, ToastError, ToastSuccess } from "../../../Component/Helper";
import { useQueryClient } from "@tanstack/react-query";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "min(400px, 90%)",
    // bgcolor: "background.paper",
    bgcolor: "var(--named_wishlist_popup_bg_color, #fff)",
      border: '1px solid var(--named_wishlist_popup_hairline_color, #eee)',
      borderRadius: '0.3rem',
    boxShadow: 24,
    p: 4,
  };

function WishlistInputModal ({openWishlistInputModal, handleCloseWishlistInputModal, wishlist_name, named_wishlist_master_id}) {
   const {register, formState: {errors}, handleSubmit} = useForm({
    defaultValues: {
        wishlist_name: wishlist_name || ""
    }
   })
   const queryClient = useQueryClient()

   const shortlist_label = get_property_settings("shortlist_label")
   
   const headerName = wishlist_name ? `Rename ${shortlist_label}` : `Create New ${shortlist_label}`
   
   
   const onSubmit = async (data) => {
        const endPoint = wishlist_name ? ApiList.rename_named_wishlist : ApiList.create_named_wishlist;

        const params = wishlist_name ? {
            ...data,
            named_wishlist_master_id
        } : data

        try{

            const wishlist_input_response = await fetch_data_from_api(endPoint, endPoint, params) 
            if(wishlist_input_response?.ack === "1" || wishlist_input_response?.ack === 1 ){
                ToastSuccess(wishlist_input_response.msg)
                queryClient.invalidateQueries({ queryKey: ['named_wishlist'], exact: true })
            }else if(wishlist_input_response?.ack === "0" || wishlist_input_response?.ack === 0){
                ToastError(wishlist_input_response.error)
            }
        }catch(e){
            ToastError("Something went wrong")
        }finally {
            
            handleCloseWishlistInputModal()
        }
   }

    return(

    <Modal
    open={openWishlistInputModal}
    onClose={handleCloseWishlistInputModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="create-delete-wishlist-modal"
    disableAutoFocus
  >
    <Box sx={style}>
        <div className="create-wishlist-section">
          <header>
            
          {headerName === "Create New Wishlist" ? <CgPlayListAdd className="add-icon"/> :
          <RiEdit2Fill className="add-icon"/>}
              <h3>{headerName}</h3>
          </header>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <input placeholder={`Enter ${shortlist_label} Name`} {...register("wishlist_name", {
                required: {
                    value: true,
                    message: `Please enter ${shortlist_label.toLowerCase()} name`
                }
              })}/>
             {errors.wishlist_name && <p className="error-text">{errors.wishlist_name.message}</p>}
              <div className="btns">
                  <button type="submit">{wishlist_name ? "Update" : "Submit"}</button>
                  <button type="button" onClick={handleCloseWishlistInputModal}>Cancel</button>
              </div>
          </form>
        </div>
    </Box>
  </Modal>
    )
}


export default WishlistInputModal