import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { get_property_settings } from "../../Component/Helper";

function AllOrderCard({
  id,
  voucher_no,
  distributor,
  retailer,
  client_name,
  order_date,
  status_name,
  status_bg_color,
  status_fg_color,
  total,
  can_cancel_order,
  person_name,
  order_url,
  voucher_order_type,
  finalize_fields_data
}) {
  const navigate = useNavigate();

  let hide_group_by_status_order_list_view = get_property_settings("hide_group_by_status_order_list_view")
  hide_group_by_status_order_list_view = hide_group_by_status_order_list_view === "hide"

  return (
    <div
      className="all-order-card"
      onClick={() =>
        navigate(`/all-order/${id}?voucher_no=${voucher_no}`, {
          state: { 
            can_cancel_order: can_cancel_order === "Yes",
            order_url,
            finalize_fields_data
          }
        })
      }
    >
      <header>
        <div className="left-section">
          <h4>ORDER NO: {voucher_no}</h4>
          {status_name && !hide_group_by_status_order_list_view && (
            <p
              className="tag"
              style={{
                backgroundColor: status_bg_color,
                color: status_fg_color,
              }}
            >
              {status_name}
            </p>
          )}
          {voucher_order_type && <p>Voucher Order Type: {voucher_order_type}</p>}
          <p>Placed By: {person_name}</p>
          {distributor && <p>Distributor: {distributor}</p>}
          {retailer && <p>Retailer: {retailer}</p>}
          {client_name && <p>Client: {client_name}</p>}
        </div>

        <div className="right-section">
          <p className="date">{dayjs(order_date).format("DD-MMM-YYYY")}</p>
          {/* {status_name && (
            <p
              className="tag"
              style={{
                backgroundColor: status_bg_color,
                color: status_fg_color,
              }}
            >
              {status_name}
            </p>
          )} */}
        </div>
      </header>

      <div className="total">
        {total?.label?.map((label, index) => {
          const clsName =
            total?.label.length % 2 > 0 && total?.label.length - 2 === index
              ? "border-bottom-label"
              : "";
          return (
            <div className={`total-label ${clsName}`} key={`label-${index}`}>
              <span className="total-label-subtitle">{label}</span>
              <span className="total-label-value">{total?.values[index]}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AllOrderCard;
