/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { get_property_settings } from "../../../../Component/Helper";
import { CustomOrderFileContext } from "../../Context/CustomOrderContext";
import { useLocation } from "react-router-dom";
import FullScreenImageSlider from "../../../../Component/FullScreenImageSlider/FullScreenImageSlider";

function FileInput({ image_fields = null }) {
  const {
    setValue,
    setError,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const MAX_IMAGE = image_fields && image_fields?.length > 0 ? (5 - image_fields?.length) : 5
  const image_name_watch = watch("image_name");
  const syod_image_required =
    get_property_settings("syod_image_required") === "Yes";
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));
  let oms_add_multiple_item_app_split_by_designno_and_image =
    get_property_settings(
      "oms_add_multiple_item_app_split_by_designno_and_image"
    );
  oms_add_multiple_item_app_split_by_designno_and_image =
    oms_add_multiple_item_app_split_by_designno_and_image === "Yes";
  const { file } = useContext(CustomOrderFileContext) || {};

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
   
    const newImages = [];
    if (!image_fields && syod_image_required && files.length === 0) {
      setError("image_name", {
        type: "required",
        message: "Please add atleast one image",
      });
      setValue("image_name", []);
    } else if (files.length > MAX_IMAGE) {
      
      setError("image_name", {
        type: "max-image",
        message: `Max. ${MAX_IMAGE} images required`,
      });

      return;
    } else {
      clearErrors("image_name");
    }

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        newImages.push({ src: e.target.result, file });

        // Update the state after all files are read
        if (newImages.length === files.length) {
          // setImages((prevImages) => [...prevImages, ...newImages]);
          if (Array.isArray(image_name_watch)) {
            // setValue("image_name", [...image_name_watch, ...newImages])
            setValue("image_name", [...newImages]);
          } else {
            setValue("image_name", [...newImages]);
          }
        }
      };

      reader.readAsDataURL(file);
    });
  };

  const removeImage = (index) => {
    const newImages = image_name_watch.filter((_, i) => i !== index);
    updateFileInput(newImages);
    setValue("image_name", newImages);

    if (!image_fields && syod_image_required && newImages?.length === 0) {
      setError("image_name", {
        type: "required",
        message: "Please add atleast one image",
      });
    }

    // setImages((prevImages) => {
    //   const newImages = prevImages.filter((_, i) => i !== index);
    //   updateFileInput(newImages);
    //   return newImages;
    // });
  };

  const updateFileInput = (imageList) => {
    const dataTransfer = new DataTransfer();

    Array.from(imageList).forEach((image) => {
      dataTransfer.items.add(image.file);
    });
    //setValue("image_name", imageList)
    document.getElementById("custom-order-img-browse").files =
      dataTransfer.files;
  };

  // useEffect(() => {

  //   if(syod_image_required && Array.isArray(image_name_watch) && !image_name_watch.length){
  //     setError("image_name", {
  //       type: "required",
  //       message: "Please add atleast one image",
  //     })
  //   }else{
  //     clearErrors("image_name")
  //   }

  // }, [setError, image_name_watch])

  useEffect(() => {
    //|| (syod_image_required && image_name_watch?.length > 0 && searchParams?.update_id)
    if (
      !searchParams?.update_id ||
      !file[searchParams?.update_id] ||
      image_name_watch?.length > 0
    )
      return;

    setValue("image_name", file[searchParams?.update_id]);

    updateFileInput(file[searchParams?.update_id]);
  }, [
    searchParams?.update_id,
    document.getElementById("custom-order-img-browse"),
  ]);

  

  return (
    <div className="file-input-container form-input-group">
      <label>
        <span>Attach upto {5} images<span className="required">*</span></span>
      </label>
      <input
        type="file"
        placeholder={`Attach upto ${5} images`}
        multiple
        id="custom-order-img-browse"
        onChange={handleFileChange}
        name="image_name"
        accept="image/*"
      />
      {errors?.image_name && (
        <p className="error-input">{errors?.image_name?.message}</p>
      )}
      <div className="images-container">
        {oms_add_multiple_item_app_split_by_designno_and_image &&
        image_fields &&
        Array.isArray(image_fields)
          ? image_fields.map((img, index) => (
              <div className="image-wrapper" key={index}>
                <span className="number">{index + 1}</span>
                <FullScreenImageSlider
              imagesArr={[img.image_path.replace("large", "zoom")]}
              showThumbnailPreview={false}
            >

                <img src={img.image_path} alt="product" />
            </FullScreenImageSlider>
              </div>
            ))
          : null}
        {Array.isArray(image_name_watch) &&
          image_name_watch?.map((img, index) => (
            <div className="image-wrapper" key={index}>
              <span className="number">{index + 1}</span>
              <span className="close-btn" onClick={() => removeImage(index)}>
                &times;
              </span>
              <FullScreenImageSlider
              imagesArr={[img.src.replace("large", "zoom")]}
              showThumbnailPreview={false}
            >

              <img src={img.src} alt="product"/>
            </FullScreenImageSlider>
            </div>
          ))}
      </div>
    </div>
  );
}

export default FileInput;
