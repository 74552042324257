import React, {   useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderUserMenu from "./HeaderUserMenu";
import { useSelector } from "react-redux";
import { FaSearch } from "react-icons/fa";
import SearchModal from "../../SearchModal/SearchModal";
import { getUserDetails, get_is_myc_web } from "../../../redux/Reducer/reducer";
import { get_bulk_settings, get_informationFromSessionID, get_userData_from_activeUser } from "../../Helper";
import ModalDialog from "../../ModalDialog";
import { logOutUser } from "../../Helper";
import { FaFilter } from "react-icons/fa";
import ViewStyle from "./components/ViewStyle/ViewStyle";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";
import { getCustomValue } from "../../../Custom/CompanyList";
import CustomOrderFilterOption from "../../../Pages/CustomOrder/Components/CustomOrderFilterOption/CustomOrderFilterOption";

function HeaderBottom({ logoImg, setOpenMobileMenu }) {
  const settings = useSelector((state) => state.reducer.settings);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const open_custom_order_filter_modal_ref = useRef(null)
  const location = useLocation()
  const is_myc_web = useSelector(get_is_myc_web);
  const userID =
    useSelector(getUserDetails)?.userID ||
    get_informationFromSessionID("userID");
  const company_code = get_informationFromSessionID("companyCode");

  const center_compnay_logo = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.center_compnay_logo)

  let {default_image, cdn} = get_bulk_settings(["default_image", "cdn"])
  default_image = `${cdn}uploads/default_image/${default_image}`

  const show_left_header_extra_company_logo = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_left_header_extra_company_logo)
  let hide_menu_when_user_not_loggedin = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.hide_menu_when_user_not_loggedin)
  hide_menu_when_user_not_loggedin = !userID && !!hide_menu_when_user_not_loggedin

  //For Filter Option
  const user_data = get_userData_from_activeUser() || {};
  const isAdmin = user_data?.user_type === "14" || user_data?.user_type === "1";
  const showFilter = location.pathname === "/customer-order" && isAdmin
  
  return (
    <>
      
      {showLogoutModal && <ModalDialog
        show={showLogoutModal}
        setShow={setShowLogoutModal}
        action={logOutUser}
        toastMsg={"Your Successfully logout"}
      />}
      <div className="ec-header-bottom">
        <section className="search-modal-container">
          {!is_myc_web && showSearch && (
            <SearchModal
              showSearch={showSearch}
              setShowSearch={setShowSearch}
            />
          )}
        </section>
        <div className="header-bottom position-relative">
          <div className="row header-mob-nav">
            <div className="align-self-center d-sm-none d-block logo-container-mob">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {!hide_menu_when_user_not_loggedin && <a
                href="#"
                className={`ec-header-btn ec-side-toggle burger-menu ${
                  is_myc_web ? "d-none" : "d-lg-none"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenMobileMenu(true);
                }}
              >
                <i className="fi fi-rr-menu-burger"></i>
              </a>}

              {/* -- Ec Header Logo Start -- */}
              <div className={`header-logo`} id="header-company-logo-1">
              {show_left_header_extra_company_logo ? <Link className="header-left-extra-logo">
            <img src={default_image} alt="header-left-extra-logo" />
            </Link> : null}
                <Link to="/home">
                  <img src={logoImg} alt="Site Logo" />
                </Link>
              </div>
              {/* -- Ec Header Logo End -- */}

              {location.pathname === "/matrix-page" ? <ViewStyle/> : null}
              {!is_myc_web && (
                showFilter ? <CustomOrderFilterOption iconClassName="mobile-search"/> : <FaSearch className="mobile-search" onClick={() => setShowSearch(true)}/>
                )}
            </div>

            <div className="ec-flex justify-content-center justify-content-lg-between d-sm-flex  d-none ">
            
              {/* -- Ec Header Logo Start -- */}
              {settings && Object.keys(settings).length > 0 ? (
                <div className={`align-self-center logo-container ${center_compnay_logo ? "center_compnay_logo" : ""}`}>
                  {show_left_header_extra_company_logo ? <Link className="header-left-extra-logo">
            <img src={default_image} alt="header-left-extra-logo" />
            </Link> : null}
                  <div className={`header-logo`} id="header-company-logo-2">
                    <Link to={`/home?company_code=${company_code}`}>
                      <img src={logoImg} alt="Site Logo" />
                    </Link>
                  </div>
                </div>
              ) : null}
             
              {!is_myc_web && (
                <div className="align-self-center search header-search-icon-menu">
                  {showFilter ? (
                    
                    <CustomOrderFilterOption />
                  ) : (<div
                    className="ec-header-bottons"
                    onClick={() => setShowSearch(true)}
                  >
                    <FaSearch />
                   
                  </div>)}
                </div>
              )}
              {/* -- Header User End -- */}

              {/* -- Header User Start -- */}
              <div className="align-self-center">
                <div className="ec-header-bottons d-none d-lg-flex">
                  <HeaderUserMenu />
               
                </div>
              </div>
            

              {/* -- Header Top responsive Action -- */}
              <div
                className={`d-lg-none d-flex ${is_myc_web ? "ml-auto" : ""}`}
              >
                <div className="ec-header-bottons">
                  {/* -- Header User Start -- */}
                  <HeaderUserMenu />
                  {/* -- Header User End -- */}

                  {/* -- Header menu Start -- */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  {!is_myc_web && !hide_menu_when_user_not_loggedin && <a
                    href="#"
                    className="ec-header-btn ec-side-toggle d-lg-none"
                    onClick={(e) => {
                      e.preventDefault();
                      !is_myc_web && setOpenMobileMenu(true);
                    }}
                  >
                     <i className="fi fi-rr-menu-burger"></i>
                    
                  </a>}
                  {/* -- Header menu End -- */}
                </div>
              </div>
              {/* -- Header Top responsive Action -- */}
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(HeaderBottom);
