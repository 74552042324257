import { useFormContext } from "react-hook-form";
import "../InputField.css";

//This is used in add retailer form only
function CountryWiseGSTInput({fields}) {
    const {register, formState: {errors}} = useFormContext()
    const {cin_label, cin_required, cin_max_length, cin_min_length, cin_regex} = fields || {};
    const short_code= "gst_number";
  return (
    <div className='input-group textfield'>
        <label>{cin_label}{cin_required === "1" || fields?.required_field === "1" ? <span className="required">*</span> : null}</label>
        <input type={"text"} {...register(short_code, {
          required: {
            value: cin_required === "1" || fields?.required_field === "1",
            message: `${cin_label} is required`
          },
          minLength: {
            value: parseInt(cin_min_length),
            message: "Invaild GST No"
          },
          maxLength: {
            value: parseInt(cin_max_length),
            message:  "Invaild GST No"
          },
          pattern: {
            value: `/${cin_regex}/`,
            message: "Invaild GST No"
          },
          
          
        })}/>
        {errors[short_code] ? <p className="error-text">{errors[short_code]?.message}</p> : null}
    </div>
  )
}

export default CountryWiseGSTInput