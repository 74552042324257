import React, { useEffect, useRef, useState } from "react";
import "./MenuDropdown.css";
import { Link } from "react-router-dom";
import { createSearchURLParams, get_bulk_settings, get_informationFromSessionID, get_property_settings, get_userData_from_activeUser, show_custom_order_menu } from "../../Helper";

import { getCustomValue } from "../../../Custom/CompanyList";
import { CUSTOMIZIED_COMPANY_PROPERTY } from "../../../Custom/CompanyCustomization";
import { selected_matster } from "../../../utils";
import { useSelector } from "react-redux";
import { getCatMaster } from "../../../redux/Reducer/reducer";

function MultiListMenu({ cat_name, splitArray, id }) {
  const listRef = useRef(null);
  const megaMenuRef = useRef(null);
  const horizontalMenu = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.horizontal_menu
  );
  const center_horizontal_menu = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.center_horizontal_menu
  );

  const show_thumbnail_in_menu_dropdown = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_thumbnail_in_menu_dropdown)

  

  useEffect(() => {
    const BoundingClientRect = megaMenuRef?.current.getBoundingClientRect();
    if (
      center_horizontal_menu &&
      BoundingClientRect.width < window.innerWidth
    ) {
      const marginOffset = (window.innerWidth - BoundingClientRect.width) / 2;
      const leftValue = (BoundingClientRect.left - marginOffset) * -1;
      if (leftValue === -0) return;

      megaMenuRef.current.style.left = `${leftValue}px`;
    } else if (BoundingClientRect.left < 0) {
      if (horizontalMenu?.status) {
        const windowWidth = window.innerWidth;
        const margin =
          (windowWidth - BoundingClientRect?.width) / 2 +
          Math.abs(BoundingClientRect.left);

        const translateX = `translateX(calc(-50% + ${margin}px)`;
        megaMenuRef.current.style.transform = translateX;
      } else {
        megaMenuRef?.current?.classList.add("fixd-left-overflow");
      }
    } else if (BoundingClientRect.right > window.innerWidth) {
      if (horizontalMenu?.status) {
        const windowWidth = window.innerWidth;
        const margin =
          (windowWidth - BoundingClientRect?.width) / 2 +
          Math.abs(BoundingClientRect.right - windowWidth);

        const translateX = `translateX(calc(-50% - ${margin}px)`;
        megaMenuRef.current.style.transform = translateX;
      } else {
        megaMenuRef?.current?.classList.add("fixd-right-overflow");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasSubcategory = Array.isArray(splitArray) && splitArray.length > 0;

  const ParentMenu_Redirection = () => {
    if (hasSubcategory) {
      return createSearchURLParams("home", { cat_id: id });
    } else {
      return createSearchURLParams("matrix-page", {
        cat_id: id,
        mode: "filter",
        page: 0,
      });
    }
  };

  const {default_image_type_matrix, default_sort, cdn, default_image} = get_bulk_settings(["default_image_type_matrix", "default_sort", "cdn", "default_image"])
  const default_thumb_img = `${cdn}uploads/default_image/${default_image}`
  const ChildMenu_Redirection = (data) => {
    let { subcategory, show_subcat, id, img_type, cat_name } = data || {};
    //For All Products
    const all_products_params = cat_name.toLowerCase() === "all products" ? {mode: "all", img_type: default_image_type_matrix, sort: default_sort} : {}
    show_subcat = show_subcat === "Yes";
    if (show_subcat && Array.isArray(subcategory) && subcategory.length > 0) {
      return createSearchURLParams("home", { cat_id: id });
    } else {
      return createSearchURLParams("matrix-page", {
        cat_id: id,
        mode: "filter",
        page: 0,
        img_type: img_type || "",
        ...all_products_params
      });
    }
  };

  if (horizontalMenu?.status && splitArray.length > 1 && splitArray.length < 4) {
    horizontalMenu.custom_dropdown_style.columns = {
      gridTemplateColumns: `repeat(${splitArray.length}, 1fr)`,
      placeItems: "center",
      alignItems: "start",
    };
  } else if (horizontalMenu?.status) {
    horizontalMenu.custom_dropdown_style.columns = {
      gridTemplateColumns: `repeat(4, 1fr)`,
    };
  }

  return (
    <li className="dropdown position-static">
      <Link to={ParentMenu_Redirection()}>{cat_name}</Link>
      <ul
        className={`mega-menu d-block ${
          center_horizontal_menu ? "center-horizontal-menu" : ""
        }`}
        ref={megaMenuRef}
        style={
          horizontalMenu?.status
            ? horizontalMenu?.custom_dropdown_style?.mega_menu
            : {}
        }
      >
        <li
          className="d-flex"
          ref={listRef}
          style={
            horizontalMenu?.status
              ? horizontalMenu?.custom_dropdown_style?.columns
              : {}
          }
        >
          {splitArray.map((arr, index) => {
            
          return (
            <ul className={`d-block ${show_thumbnail_in_menu_dropdown ? "thumbnail-image-with-menu" : ""}`} key={index}>
              {arr.map((data, i) => {
                 const img = `${cdn}uploads/branding_image/category/${data?.category_img}`
                return (
                <li key={i}>
                  <Link
                    to={ChildMenu_Redirection(data)}
                    className={`categoryLinks ${data?.className || ""}`}
                  >
                    {show_thumbnail_in_menu_dropdown && <span className="menu-thumb">
                      {data?.category_img ? <img src={img} alt="" /> : <img src={default_thumb_img} alt=""/>}
                    </span>}
                    <span className="menu-text">{data.cat_name}</span>
                  </Link>
                </li>
              )
            }
              
              )}
            </ul>
          )
          }
          )}
        </li>
      </ul>
    </li>
  );
}

function SingleListMenu({ cat_name, subcategory, id, all_category_button }) {
  const searchParams = Object.fromEntries(
    new URLSearchParams(document.location.search)
  );
  const show_thumbnail_in_menu_dropdown = getCustomValue(CUSTOMIZIED_COMPANY_PROPERTY.show_thumbnail_in_menu_dropdown)
  const subMenuRef = useRef(null);
  const hasSubcategory = subcategory.length > 0;
  const [activeLinks, setActiveLinks] = useState(
    searchParams?.cat_id === id && !hasSubcategory ? "active-link" : ""
  );

  const cdn = get_property_settings("cdn")
  const default_image = get_property_settings("default_image")
const default_thumb_img = `${cdn}uploads/default_image/${default_image}`
  useEffect(() => {
    if (!subMenuRef.current) return;
    const BoundingClientRect = subMenuRef?.current.getBoundingClientRect();
    if (BoundingClientRect.left < 0) {
      subMenuRef?.current?.classList.add("fixd-left-overflow");
    }

    if (BoundingClientRect.right > window.innerWidth) {
      subMenuRef?.current?.classList.add("fixd-right-overflow");
    }
  }, []);

  useEffect(() => {
    setActiveLinks(
      searchParams?.cat_id === id && !hasSubcategory ? "active-link" : ""
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.cat_id]);

  const ParentMenu_Redirection = () => {
    
    const master = useSelector(getCatMaster);
      const selected_master_params = selected_matster(master)

    if (hasSubcategory) {
      return createSearchURLParams("home", { cat_id: id, ...selected_master_params });
    } else {
      return createSearchURLParams("matrix-page", {
        cat_id: id,
        mode: "filter",
        page: 0,
        ...selected_master_params
      });
    }
  };
  const ChildMenu_Redirection = (data) => {
    let { subcategory, show_subcat, id, cat_name } = data || {};
    //For All Products
    const {default_image_type_matrix, default_sort} = get_bulk_settings(["default_image_type_matrix", "default_sort"])
    const all_products_params = cat_name.toLowerCase() === "all products" ? {mode: "all", img_type: default_image_type_matrix, sort: default_sort} : {}
    show_subcat = show_subcat === "Yes";

 
    
    const master = useSelector(getCatMaster);
   const selected_master_params = selected_matster(master)


    if (show_subcat && Array.isArray(subcategory) && subcategory?.length > 0) {
      return createSearchURLParams("home", { cat_id: id, ...selected_master_params });
    } else {
      return createSearchURLParams("matrix-page", {
        cat_id: id,
        mode: "filter",
        page: 0,
        ...all_products_params,
        ...selected_master_params
      });
    }
  };

  return (
    <li className="dropdown single-menu">
      <Link to={ParentMenu_Redirection()} className={`${activeLinks}`}>
        {cat_name}
      </Link>

      {subcategory.length > 0 && (
        <ul className={`sub-menu  ${show_thumbnail_in_menu_dropdown ? "thumbnail-image-with-menu" : ""}`} ref={subMenuRef}>
          {all_category_button ? (
            <li className="all">
              <Link
                to={createSearchURLParams("matrix-page", {
                  cat_id: id,
                  mode: "filter",
                  page: 0,
                })}
                className="categoryLinks"
              >
                All
              </Link>
            </li>
          ) : null}
          {subcategory.map((data, index) => {
            const img = `${cdn}uploads/branding_image/category/${data?.category_img}` 
           
            return (
            <li key={index} className="sub-menu-list">
              <Link to={ChildMenu_Redirection(data)} className={`categoryLinks`}>
               {show_thumbnail_in_menu_dropdown && <span className="menu-thumb">
                  {data?.category_img ? <img src={img} alt="" /> : <img src={default_thumb_img} alt=""/>}
                </span>}
                <span className="menu-text">{data.cat_name}</span>
              </Link>
            </li>
          )
        }
          
          )}
        </ul>
      )}
    </li>
  );
}

function MultiListMenuFn(subcategory, LIST_MAX_COUNT) {
  const splitArray = [];
  let tempArr = [];
  let extracted_subcategory = [];
  
  const show_extracted_subcategory_in_desktop_dropdown_menu = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.show_extracted_subcategory_in_desktop_dropdown_menu
  );

  if(show_extracted_subcategory_in_desktop_dropdown_menu?.status){
    const find_extracted_cat = subcategory.find(cat => show_extracted_subcategory_in_desktop_dropdown_menu.extracted_categories.includes(cat.cat_name))

    if(!!find_extracted_cat){
      const extracted_sub_cat = find_extracted_cat.subcategory.map(cat => ({...cat, className: "extracted-subcategory-sub-menu"}))
      extracted_subcategory = [{...find_extracted_cat, className: "extracted-subcategory-main-menu"}, ...extracted_sub_cat]
    }
  }


  
  subcategory?.forEach((arr) => {
    if(show_extracted_subcategory_in_desktop_dropdown_menu?.status &&
      show_extracted_subcategory_in_desktop_dropdown_menu?.extracted_categories?.includes(arr.cat_name)){
      //skip extracted categories from menu (e.g mens in Kalasha online)
    }
    else if (tempArr.length < LIST_MAX_COUNT) {
      tempArr.push(arr);
    } else if (tempArr.length === LIST_MAX_COUNT) {
      splitArray.push(tempArr);
      tempArr = [];
      tempArr.push(arr);
    } else {
      tempArr.push(arr);
    }
  });

  splitArray.push(tempArr);
  if(extracted_subcategory.length > 0){
    splitArray.push(extracted_subcategory);
  }
  return splitArray;
}

function MenuDropdownList({
  cat_name,
  subcategory,
  setShowCategoryModal,
  id,
  all_category_button,
}) {
  const LIST_MAX_COUNT = 6;
  const isMultiListMenu = subcategory.length > 6;
  const horizontal_menu = getCustomValue(
    CUSTOMIZIED_COMPANY_PROPERTY.horizontal_menu
  );

  if (horizontal_menu?.status) {
    // return subcategory.map((subCat) => (
    //   <HorizontalMenu
    //     {...subCat}
    //     key={subCat.id}
    //     setShowCategoryModal={setShowCategoryModal}
    //     horizontal_menu={horizontal_menu}
    //   />
    // ));
    return (
      <HorizontalMenu
        cat_name={cat_name}
        subcategory={subcategory}
        setShowCategoryModal={setShowCategoryModal}
        id={id}
        horizontal_menu={horizontal_menu}
      />
    );
  }

  const splitArray = MultiListMenuFn(subcategory, LIST_MAX_COUNT);


  return isMultiListMenu ? (
    <MultiListMenu
      splitArray={splitArray}
      cat_name={cat_name}
      setShowCategoryModal={setShowCategoryModal}
      id={id}
    />
  ) : (
    <SingleListMenu
      subcategory={subcategory}
      cat_name={cat_name}
      setShowCategoryModal={setShowCategoryModal}
      id={id}
      all_category_button={all_category_button}
    />
  );
}

function HorizontalMenu({
  cat_name,
  subcategory,
  setShowCategoryModal,
  id,
  horizontal_menu,
}) {
  const LIST_MAX_COUNT = horizontal_menu?.CUSTOM_MAX_COUNT?.[cat_name] || horizontal_menu?.LIST_MAX_COUNT || 6;
  const isMultiListMenu = subcategory?.length > LIST_MAX_COUNT;
  
  const splitArray = MultiListMenuFn(subcategory, LIST_MAX_COUNT);

  return isMultiListMenu ? (
    <MultiListMenu
      splitArray={splitArray}
      cat_name={cat_name}
      setShowCategoryModal={setShowCategoryModal}
      id={id}
    />
  ) : (
    <SingleListMenu
      subcategory={subcategory}
      cat_name={cat_name}
      setShowCategoryModal={setShowCategoryModal}
      id={id}
    />
  );
}

export default MenuDropdownList;
