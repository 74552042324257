/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { ApiList} from "../../Api/ApiList";

import { delete_informationFromSessionID, get_property_settings, set_activeUser, set_informationToSessionID, set_userData_to_activeUser, ToastError } from "../../Component/Helper";
import { fetch_data_from_api } from "../../Api/APICalls";
import { maskString } from "../../utils";
import { set_inCartWishList, set_open_preferences_modal, setIsLoggedIn } from "../../redux/Reducer/reducer";
import { useDispatch } from "react-redux";

function RegistrationVerification({ ResOTP, formData, refetchOTP}) {
  const [show, setShow] = useState(false);
  let auto_login_post_register = get_property_settings("auto_login_post_register")
  auto_login_post_register = auto_login_post_register === "Yes"

  let has_user_preferences = get_property_settings("has_user_preferences")
  has_user_preferences = has_user_preferences === "1";
  
  const [otp, setOtp] = useState("");
  const [showResendButton, setShowResendButton] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const [resData, setResData] = useState();
  const navigate = useNavigate();
//  const company_code = get_informationFromSessionID("companyCode");
const dispatch = useDispatch()

  const handleResend = () => {
    setOtp("")
    refetchOTP()
    setShowResendButton(false);
    setCountdown(60);
  };

  const startCountdown = () => {
    setTimeout(() => {
      setShowResendButton(true);
    }, 60000);
  };

  useEffect(() => {
    if (!showResendButton) {
      startCountdown();
    }
  }, [showResendButton]);

  useEffect(() => {
    if (!showResendButton && countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [showResendButton, countdown]);

  const verifyOTP = async () => {
    if (ResOTP === otp) {
        const userRegistration_response = await fetch_data_from_api(`${ApiList.UserRegistration}`, 'registration', {
          login_from: "web",
          lat: 0,
          long: 0,
          device_model: "",
          device_ip: "",
          ref_code: "",
          device_id: "",
          "one-signal-user-id": "",
          gen_referral_code: "",
          string_random_internal: 7723595534,
          ...formData
        })
       
        
        if (userRegistration_response?.error) {
          return ToastError(userRegistration_response?.error)
        }else{
          setResData(userRegistration_response);
          setShow(true);
          let after_login_navigation = "/login"
          
          if(auto_login_post_register){
            const login_response = await fetch_data_from_api(ApiList?.login, "login",  {
              login_from: "web",
              password: formData?.password,
              mobile_no: formData?.mobile_no,
              long: 0,
              lat: 0,
              device_token: "",
              device_model: "",
              device_ip: "",
              device_id: "",
            })
            
            if(login_response?.ack === "1"){
              delete_informationFromSessionID("country_code");
              set_informationToSessionID({ userID: login_response.user_id });
              set_activeUser();
              set_userData_to_activeUser(login_response);
              dispatch(
                setIsLoggedIn({ status: true, userID: login_response.user_id })
              );
             
              dispatch(set_inCartWishList({
                in_cart_id_dm: login_response.in_cart_id_dm,
                in_cart_id_im: login_response.in_cart_id_im,
                in_wishlist_id_dm: login_response.in_wishlist_id_dm,
                in_wishlist_id_im: login_response.in_wishlist_id_im,
              }))

              if(has_user_preferences){
                dispatch(set_open_preferences_modal(true))
              }

              after_login_navigation = "/home"
            }
          }
          setTimeout(() => {
            setShow(false);
            navigate(after_login_navigation);
          }, 2000);
        }

    }else{
      ToastError("Invaild OTP")
    }
  };

  const handleKeyDown = (event) => {
    const { ctrlKey, shiftKey, key } = event;
    const lowercaseKey = key.toLowerCase();

    if (ctrlKey && shiftKey && lowercaseKey === "i") {
      event.preventDefault();
    }

    if (ctrlKey && shiftKey && lowercaseKey === "j") {
      event.preventDefault();
    }

    if (ctrlKey && lowercaseKey === "u") {
      event.preventDefault();
    }
    if (event.key === "F12" || event.keyCode === 123) {
      event.preventDefault();

      // console.log(
      //   "Opening developer tools is disabled. Please do not use developer tools without proper authorization."
      // );
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault();

    // console.log(
    //   "Right-click is disabled. Developer tools should not be accessed without proper authorization."
    // );
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="container" id="Verification">
      <div className="outer-box w-100 d-flex justify-content-center align-items-center">
        <div className="box text-center border rounded d-flex flex-column justify-content-center align-items-center p-5 reg-verification-container">
          <div className="h1 m-1 ">VERIFICATION CODE</div>
          <div className="m-1 font-weight-light">
            Please enter 6 digit code sent on
            <span className="font-weight-normal">
              {" "}
              {formData?.country_code} {maskString("mobile", "X", formData?.mobile_no, 0)}
            </span> and{" "}
            <span className="font-weight-normal"> {maskString("email", "X", formData?.email_id, 4)}</span>
          </div>
          <span className="ec-login-wrap">
          <div className="otp-inputs">
            <OTPInput
              id="input"
              inputType="number"
              value={otp}
              onChange={setOtp}
              isInputNum={true}
              numInputs={6}
              // renderSeparator={<div className="mx-1 mx-sm-3"></div>}
              shouldAutoFocus
              renderInput={(props) => (
                <input type="number" {...props} className="no-selection fieldValue input-opt-box" />
              )}
              inputStyle={{ border: "1px solid #eee", color: "var(--login_register_fg_color)" }}
              onBlur={(e) => e.traget.style.color = "var(--login_register_fg_color)"}
            />
          </div>
          </span>
          <div className="m-1">
            <button
              disabled={otp.length === 6 ? false : true}
              onClick={() => {
                verifyOTP();
              }}
              className="btn btn-primary primary_button"
            >
              Verify OTP
            </button>
          </div>
          <div className="m-1">
            {showResendButton ? (
              <button onClick={handleResend}>Resend Verification Code</button>
            ) : (
              <p>Resend in {countdown} seconds</p>
            )}
          </div>
          <div className="m-1">
            <button className="font-weight-bold">No Code?</button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        centered
        close
        onHide={() => setShow(false)}
        id="verificationModal"
      >
        {/* <ModalHeader closeButton={true}></ModalHeader> */}
        <ModalBody className="d-flex flex-column justify-content-center reg-verification-confirmation-modal">
          <div className="CheckIcon">
            <FontAwesomeIcon icon={faCircleCheck} style={{ color: "green" }} />
          </div>
          <div className="h1">Welcome</div>
          <div className="font-weight-light h4">{(resData && resData.msg)}</div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default RegistrationVerification;
